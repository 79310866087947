import React, {Component} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const baseURL= `${process.env.REACT_APP_API_URL}`;

class ExportFscpDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
          checklists:[],
        }
    }
    // fetchSummaryDataHandler(accesstoken,periodid) {
    //     //const {periodid,accesstoken} = this.props;    
    //     const url= `${baseURL}/fscp/checklist_by_period/?period_id=${periodid}`;
    //     fetch(url,{
    //       //signal: signal,
    //       method: 'GET',
    //       headers: {
    //         'Authorization' : `Bearer google-oauth2 ${accesstoken}`
    //       }
    //     })
    //     .then(response => response.json())
    //     .then((jsonStr)=>{
    //       this.setState({checklists: jsonStr});  
    //       console.log(this.state.checklists)
    //     });
    //   };

    componentDidMount(){
        // const {periodid,accesstoken,periodName} = this.props;    
        // this.fetchSummaryDataHandler(accesstoken,periodid)
        //console.log("exp acc token",this.statchecklists)
    }

    renderTableHeader() {
        return (
        <>
            <th key="checklist number">checklist number</th>
            {/* <th key="checklist line id">checklist line id</th> */}
            {/* <th key="template id">template id</th> */}
            <th key="template type">template type</th>
            <th key="line id">line id</th>
            <th key="task group">task group</th>
            <th key="item">item</th>
            <th key="frequency">frequency</th>
            <th key="description">description</th>
            <th key="documentation requirement">documentation requirement</th>
            <th key="working paper reference">working paper reference</th>
            {/* <th key="prepared by">prepared by</th> */}
            <th key="prepared by user">prepared by user</th>
            <th key="prepared date">prepared date</th>
            <th key="reviewed by user">reviewed by user</th>
            {/* <th key="reviewed by">reviewed by</th> */}
            <th key="reviewed date">reviewed date</th>
            <th key="conclusion">conclusion</th>
            <th key="matters to highlight">matters to highlight</th>
            {/* <th key="action plan">action plan</th> */}
            <th key="control">control</th>
            <th key="key report">key report</th>
            <th key="status">status</th>
            {/* <th key="locked by">locked by</th>
            <th key="locked by user">locked by user</th> */}
        </>
        )
    }

    setHTML(props){
        return props?{__html: props.replace(/<div>/g, "<br style=mso-data-placement:same-cell; />").replace(/<\/div>/g,"").replace(/<br>/g, "<br style=mso-data-placement:same-cell; />")}:{__html: props}
    }

    renderTableData() {
        const {checklists} = this.props;
        // this.setState({chfilter:checklists.filter((mth)=>{return mth.matters_to_highlight !== null && mth.matters_to_highlight !==''})})
        // console.log(checklists.filter((mth)=>{return mth.matters_to_highlight !== null && mth.matters_to_highlight !==''}))
        return checklists.filter((mth)=>{return mth.matters_to_highlight !== null && mth.matters_to_highlight !==''}).map((checklist) => {
                   const { checklist_number,checklist_line_id, 
                    template_id, template_type, line_id, task_group, item, frequency, description, documentation_requirement, 
                           working_paper_reference, prepared_by, prepared_by_user ,prepared_date, reviewed_by, reviewed_by_user, reviewed_date, conclusion, matters_to_highlight,
                        //    action_plan,
                            control, key_report, status
                            //, locked_by, locked_by_user
                   } = checklist;
           return (
              <tr key={checklist_line_id}>
                 <td>{checklist_number}</td>
                 {/* <td>{checklist_line_id}</td> */}
                 {/* <td>{template_id}</td> */}
                 <td>{template_type.charAt(0).toUpperCase() + template_type.slice(1).toLowerCase() + ' Specific'}</td>
                 <td>{line_id}</td>
                 <td>{task_group}</td>
                 <td>{item}</td>
                 <td>{frequency}</td>
                 <td>{description}</td>
                 <td dangerouslySetInnerHTML={this.setHTML(documentation_requirement)}/>
                 <td dangerouslySetInnerHTML={this.setHTML(working_paper_reference)}/>
                 {/* <td>{prepared_by}</td> */}
                 <td>{prepared_by_user}</td>
                 <td>{prepared_date}</td>
                 <td>{reviewed_by_user}</td>
                 {/* <td>{reviewed_by}</td> */}
                 <td>{reviewed_date}</td>
                 <td dangerouslySetInnerHTML={this.setHTML(conclusion)}/>
                 <td dangerouslySetInnerHTML={this.setHTML(matters_to_highlight)}/>
                 {/* <td>{action_plan}</td> */}
                 <td dangerouslySetInnerHTML={this.setHTML(control)}/>
                 <td dangerouslySetInnerHTML={this.setHTML(key_report)}/>
                 <td>{status}</td>
                 {/* <td>{locked_by}</td>
                 <td>{locked_by_user}</td> */}
              </tr>
           )
        }) 
    }

    // renderTableDatamth() {
    //     const {checklists} = this.props;
    //     // this.setState({chfilter:checklists.filter((mth)=>{return mth.matters_to_highlight !== null && mth.matters_to_highlight !==''})})
    //     // console.log(checklists.filter((mth)=>{return mth.matters_to_highlight !== null && mth.matters_to_highlight !==''}))
    //     return checklists.filter((mth)=>{return mth.matters_to_highlight !== null && mth.matters_to_highlight !==''}).map((checklist) => {
    //        const { checklist_id, checklist_line_id, template_id, template_type, line_id, task_group, item, frequency, description, documentation_requirement, 
    //                working_paper_reference, prepared_by, prepared_by_user ,prepared_date, reviewed_by, reviewed_by_user, reviewed_date, conclusion, matters_to_highlight,
    //             //    action_plan,
    //                 control, key_report, status, locked_by, locked_by_user
    //        } = checklist;
    //        return (
    //           <tr key={checklist_line_id}>
    //              <td>{checklist_id}</td>
    //              <td>{checklist_line_id}</td>
    //              <td>{template_id}</td>
    //              <td>{template_type.charAt(0).toUpperCase() + template_type.slice(1).toLowerCase() + ' Specific'}</td>
    //              <td>{line_id}</td>
    //              <td>{task_group}</td>
    //              <td>{item}</td>
    //              <td>{frequency}</td>
    //              <td>{description}</td>
    //              <td>{documentation_requirement}</td>
    //              <td dangerouslySetInnerHTML={this.setHTML(working_paper_reference)}/>
    //              <td>{prepared_by}</td>
    //              <td>{prepared_by_user}</td>
    //              <td>{prepared_date}</td>
    //              <td>{reviewed_by_user}</td>
    //              <td>{reviewed_by}</td>
    //              <td>{reviewed_date}</td>
    //              <td dangerouslySetInnerHTML={this.setHTML(conclusion)}/>
    //              <td dangerouslySetInnerHTML={this.setHTML(matters_to_highlight)}/>
    //              {/* <td>{action_plan}</td> */}
    //              <td dangerouslySetInnerHTML={this.setHTML(control)}/>
    //              <td dangerouslySetInnerHTML={this.setHTML(key_report)}/>
    //              <td>{status}</td>
    //              <td>{locked_by}</td>
    //              <td>{locked_by_user}</td>
    //           </tr>
    //        )
    //     }) 
    // }

    render() {
        //console.log("ch",this.props.checklists)
        const {periodName,checklists,searchexport} = this.props;    
        const fileName = `MattersToHiglightReport-${periodName}`;
        const {periodid,accesstoken} = this.props;    
        // console.log(periodid)
        // this.fetchSummaryDataHandler(accesstoken,periodid)
        return (
            <>
            {checklists.length > 0 ?
                <ReactHTMLTableToExcel
                    id="Fscp-Export"
                    className={"fscp-btn-report"}
                    table="Fscp-Table"
                    filename={fileName}
                    sheet={fileName}
                    onClick={e=>console.log(e)}
                    buttonText={`${periodName} Highlight Report`}/>
                
            :searchexport?`Generating report…`:null}
            <table hidden id="Fscp-Table">
                    <tbody>
                        <tr>
                            <td>
                                <strong>Period</strong><br/>
                            </td>
                            <td>
                                <strong> : </strong><br/>
                            </td>
                            <td>
                                {periodName}<br/>
                            </td>
                        </tr>
                        <tr>
                            <td><br/>
                            </td>
                        </tr>    
                        <tr>{checklists.length > 0 && checklists?this.renderTableHeader():null}</tr>
                        {checklists.length > 0 &&checklists?this.renderTableData():null}
                    </tbody>
                </table>
            </>
        );
    }
}
 
export default ExportFscpDetail;