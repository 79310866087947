/* global gapi */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { gapi } from 'gapi-script'

import { withCookies } from 'react-cookie';

class toolbar extends Component {
    state = {
        UserName: '',
    }

    componentDidMount() {
        window.gapi.load('auth2', () => {
            window.gapi.auth2.init({
                //client_id: '484166416946-dkll31nh5n87c6so4fdh6nj7nv655ve3.apps.googleusercontent.com',
                client_id: `${process.env.REACT_APP_CLIENT_ID}`,
                cookie_policy: 'none'
            }).then(() => {
                if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
                    this.setState({ UserName: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getName() })
                }
            })
        })
    }

    logout = () => evt => {
        // window.gapi.load('auth2', () => {
        //     window.gapi.auth2.init({
        //         //client_id: '484166416946-dkll31nh5n87c6so4fdh6nj7nv655ve3.apps.googleusercontent.com',
        //         client_id: `${process.env.REACT_APP_CLIENT_ID}`,
        //         cookie_policy: 'none'
        //     }).then(() => {
        //         gapi.auth2.getAuthInstance().disconnect();
        //         this.props.cookies.remove('Logins')
        //         window.location.href = '/Login';
        //         // window.location.href=`${process.env.REACT_APP_REDIRECTLOGIN_URI}`;
        //         // window.location.href=`${process.env.REACT_APP_REDIRECT_URI}`;
        //     })
        // })
        window.location.href = window.location.hostname.includes('test') || window.location.hostname.includes('dev') ? 'https://' + window.location.hostname.replace('portal', 'workspace') :
            'https://' + window.location.hostname.replace('fscp', 'workspace')
    };

    render() {
        return (
            //<header className="toolbar" onClick={(props) => props.isMenuExpanded ? props.menuToggle : null}>
            <header className="toolbar" onClick={this.props.isMenuExpanded ? this.props.menuToggle : null}>
                <div className="toolbar-container">
                    <div>
                        {/* <Link to='/' className="fl logo"></Link> */}
                        <a href={
                            window.location.hostname === 'localhost' ? 'http://localhost:3004/' :
                                window.location.hostname.includes('test') || window.location.hostname.includes('dev') ? 'https://' + window.location.hostname.replace('portal', 'workspace') :
                                    'https://' + window.location.hostname.replace('fscp', 'workspace')
                        } className="fl logo"></a>
                    </div>
                    <div className="spacer" />
                    <div>
                        <Link to='#' className="toolbar-title">FSCP Checklist</Link>
                    </div>
                    <div className="spacer" />
                    <div className="login-info fr">
                        <Link to='/' className="fl">{this.state.UserName}</Link>
                        <Link to='/' className="btn-logout fl" style={{ cursor: 'pointer' }} onClick={this.logout()}>Logout</Link>
                    </div>
                </div>
            </header>
        )
    }
}

export default withCookies(toolbar);