import React from 'react';
import { Link } from 'react-router-dom';

const menuLists = [
    {
        listID: 'ml1',
        listName: 'menu-home',
        linkTo: '/',
        linkName: "sprite-more more-active fl",
        linkValue: 'Home'
    },
    {
        listID: 'ml2',
        listName: 'menu-item',
        linkTo: '/fscp',
        linkName: 'sprite-fscp-s fl',
        linkValue: 'FSCP'
    },
    {
        listID: 'ml3',
        listName: 'menu-item',
        linkTo: '/globalTemplate',
        linkName: 'sprite-others-s fl',
        linkValue: 'Global Template'
    },                
];
    
const ShowList = props => {
    const mLists = props.menuLists;
    const listNames = require('classnames'), linkNames = require('classnames');
    const theLists = mLists.map((mList) => {
        return ( 
                <li key={mList.listID} className={listNames(mList.listName)}>
                    <Link to={mList.linkTo}>
                        <span className={linkNames(mList.linkName)}></span>{mList.linkValue}
                    </Link>
                </li>
                )
        })    
    return theLists;                
} 

const Menulist = props => {
    let menulistClasses = 'menu-list';
    if (props.isMenuExpanded) {
        menulistClasses = 'menu-list menu-list-expanded';
    };
    
    return (
        <ul className={menulistClasses} style={{minHeight: '536px'}}>
            <ShowList menuLists={menuLists} onClick={props.menuToggle}/>
        </ul>
    );
};

export default Menulist;
