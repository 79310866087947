import React from 'react';
import { Link } from 'react-router-dom';

const menuLists = [
    {
        listID: 'hl1',
        linkTo: '/fscp',
        linkName: 'menu-icon sprite-fscp',
        spanName: 'menu-name',
        spanDesc: 'FSCP',
        spanLongDesc: 'Create, View or Edit FSCP'
    },
    {
        listID: 'hl2',
        linkTo: '/globalTemplate',
        linkName: 'menu-icon sprite-others',
        spanName: 'menu-name',
        spanDesc: 'Template',
        spanLongDesc: 'FSCP Global Template'
    },                
];

const ShowList = props => {
    const mLists = props.menuLists;
    const linkNames = require('classnames'), spanNames = require('classnames');
    const theLists = mLists.map((mList) => {
        return ( 
                <li key={mList.listID}>
                    <Link to={mList.linkTo}>
                        <span className={linkNames(mList.linkName)}></span>
                    </Link>
                    <span className={spanNames(mList.spanName)}>
                        <Link to={mList.linkTo}>{mList.spanDesc}</Link>
                    </span>
                    <span>{mList.spanLongDesc}</span>
                </li>
                )
        })    
    return theLists;                
} 

const Home = props => {
    return (
        <div className="content" onClick={props.isMenuExpanded ? props.menuToggle : null}>
            <div className="menu-container" style={{height: '100%'}}>
                <ul className="menu-list">
                    <ShowList menuLists={menuLists}/>
                </ul>
            </div>
        </div>
    );
};


export default Home;
