/* global gapi */
import React,{Component} from 'react';
import {withCookies} from 'react-cookie';

class Redirect extends Component {

    componentDidMount(){
        alert("You dont have access, please approach FINSYS team for access")
        window.location.href='/Login';
    }

// SignOut = () => evt =>{
//     window.gapi.load('auth2', () => {
//         window.gapi.auth2.init({
//            client_id: '70112657995-l0qtjtojb4efi2dhi5jt8u93gqgo579m.apps.googleusercontent.com',
       
//        }).then(() => {
//             gapi.auth2.getAuthInstance().signOut()
//          } )
//    })
// }
render(){
    return(
        <div>
            {/* <button onClick={this.SignOut()}>SignOut</button> */}
        </div>
    )
}
}
export default withCookies(Redirect);