/* global gapi */
import React, { Component } from 'react';
import ReactTable from "react-table";
import './sea-react-table.css';
import withFixedColumns from "react-table-hoc-fixed-columns";
import { withFixedColumnsScrollEvent } from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import ExportFscpDetail from './ExportFscpDetail';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Logo from '../images/sprite/icon_entrylist_attachment.png'
import SuccessLogo from '../images/sprite/Success2.png'
import FailedLogo from '../images/sprite/failed.png'
import axios from 'axios';
import ReactLoading from 'react-loading';

const baseURL = `${process.env.REACT_APP_API_URL}`;


const AttachmentStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '50%',
    height: '40%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: {
    zIndex: 1000
  }
};

const alertCreateStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '22%',
    height: '25%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: {
    zIndex: 1000
  }
};

const submitmessage = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '40%',
    height: '40%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: {
    zIndex: 1000
  }
};


const SaveStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '22%',
    height: '25%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255,255,255,0.5)',
    opacity: 0.8,
    outline: 'none',
    border: '1px solid #ffffff'
  },
  overlay: {
    zIndex: 1000
  },
};


const ReactTableFixedColumns = withFixedColumnsScrollEvent(ReactTable);

class globalTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accesstoken: '',
      username: '',
      cookies: this.props.cookies.get('Logins'),
      checklistId: 0,
      periodName: null,
      entityId: '',
      periodId: null,
      checklists: [],
      templateTypes: [],
      expandedTemplates: { 0: true, 1: true, 2: true, 3: true },
      UserID: '',
      accesstype: '',
      cloneState: [],
      render: false,
      showAttachment: false,
      selectedChecklistLineId: '',
      AttachmentList: [],
      AttachmentList2: [],
      checkliststatus: '',
      lockedbystate: false,
      selectedChecklistIndex: null,
      clid: null,
      failed: 'Failed to Save',
      count: 0,
      percent: 0,
      refreshupdate: false
    };
    this.fetchDetailDataHandler = this.fetchDetailDataHandler.bind(this);
    //this.SaveLines = this.SaveLines.bind(this, true);
    this.checkBoxRef = React.createRef();
  };

  abortController = new AbortController();

  setExpandedRows = (newExpanded, index, e) => {
    this.setState({ expandedTemplates: newExpanded });
  };

  fetchDetailDataHandler = () => {

    const url = `${baseURL}/fscp/all_templates/`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        const templateTypes = [...new Set(jsonStr.map((item) => item.template_type))].map((item) => { return { template_type: item } });
        this.setState({ checklists: jsonStr, templateTypes: templateTypes });
      });
  };

  getuserid = () => {
    const { accesstoken } = this.state
    // console.log(props.original.checklist_line_id)
    const url = `${baseURL}/fscp/current_user/`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((json) => {
        this.setState({ UserID: json });
      })
      .catch(error => console.log(error))
  }

  getaccesstype = () => {
    const { accesstoken, entityId } = this.state
    // console.log(props.original.checklist_line_id)
    const url = `${baseURL}/fscp/my_entity_access/?entity_id=${entityId}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((json) => {
        //console.log("Access_Type ",json)
        //console.log("Access_Type ",json[0].access_type)
        this.setState({ accesstype: json[0].access_type });
      })
      .catch(error => console.log(error))
  }

  componentDidMount() {
    // const { params: { checklistId } } = this.props.match;
    // const { period_name, entity_id, period_id, status, checklistnumber } = this.props.location.state;
    //this.setState({ checklistId: checklistId, periodName: period_name, entityId: entity_id, periodId: period_id, checkliststatus: status, checklistnumber: checklistnumber });

    if (this.state.cookies !== "Test" && this.state.cookies) {
      window.gapi.load('auth2', () => {
        window.gapi.auth2.init({
          //client_id: '484166416946-dkll31nh5n87c6so4fdh6nj7nv655ve3.apps.googleusercontent.com',
          client_id: `${process.env.REACT_APP_CLIENT_ID}`,
          cookie_policy: 'none'
        }).then(() => {
          gapi.auth2.getAuthInstance().currentUser.listen((usr) => this.setState({ accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() }))
          this.setState({ accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() })

          this.fetchDetailDataHandler();
          this.getaccesstype();
          this.getuserid();
        })
      })
    } else {
      gapi.auth2.getAuthInstance().disconnect()
      window.location.href = `${process.env.REACT_APP_REDIRECT_URI}`;
    }
  };

  componentWillUnmount() {
    this.abortController.abort();
  }

  shouldComponentUpdate(nextProps, nextState) {
    let Total = JSON.parse(JSON.stringify(this.state.checklists))
    if (nextState.count === Total.length ||
      (this.state.isSave && nextState.count === 1) ||
      nextState.count === 0 ||
      this.state.refreshupdate) {
      return true
    }
    else {
      return false

    }
  }

  filterCaseInsensitive = ({ id, value }, row) => {
    return (
      row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
    );
  };


  render() {
    const { checklistId, periodName, entityId, checklistnumber } = this.state;
    const { isMenuExpanded, menuToggle } = this.props;

    const templateColumns = [
      {
        Header: "",
        id: "template_type",
        accessor: props => `${props.template_type.charAt(0).toUpperCase()}${props.template_type.slice(1).toLowerCase()} Specific`,
        width: 250,
        minwidth: 250,
        maxwidth: 250,
        style: {
          textAlign: "Left"
        }
      }
    ];

    const customFilter = ({ filter, onChange }) => {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
        >
          <option value="all">Show All</option>
          <option value="Prepared">Prepared</option>
          <option value="Reviewed">Reviewed</option>
          <option value="NotPrepared">Not Prepared</option>
        </select>
      );
    };

    const customFilterFrequency = ({ filter, onChange }) => {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
        >
          <option value="all">Show All</option>
          <option value="Monthly">Monthly</option>
          <option value="Quarterly">Quarterly</option>
          <option value="Annually">Annually</option>
        </select>
      );
    };

    const customFilterSegment = ({ filter, onChange }) => {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
        >
          <option value="all">Show All</option>
          <option value="DE">DE</option>
          <option value="DFS">DFS</option>
          <option value="EC">EC</option>
          <option value="OCHA">OCHA</option>
        </select>
      );
    };

    const customFilterSegmentGlobal = ({ filter, onChange }) => {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
        >
          <option value="all">Show All</option>
          <option value="GLOBAL">GLOBAL</option>
        </select>
      );
    };

    const checklistColumns = [
      {
        Header: "",
        fixed: "left",
        columns: [
          {
            Header: "",
            id: "row",
            width: 30,
            filterable: false,
            Cell: (row) => {
              return <div>{row.index + 1}</div>;
            }
          },
          {
            Header: "Segment",
            accessor: "id",
            width: 100,
            style: {
              textAlign: "Left",
              whiteSpace: 'unset'
            },
            Cell: row => <div><span title={row.value}>{row.value}</span></div>,
            filterMethod: (filter, row) => {
              if (filter.value === 'all') {
                return row
              }
              else {
                if (row[filter.id] !== null && row[filter.id] !== '') { return row[filter.id].match(filter.value) } else { console.log(row[filter.id]) }
              }
            },
            Filter: ({ filter, onChange }) =>
              customFilterSegment({ filter, onChange })
          },
          {
            Header: "Task Group",
            accessor: "task_group",
            width: 150,
            style: {
              textAlign: "Left",
              whiteSpace: 'unset'
            },
            Cell: row => <div><span title={row.value}>{row.value}</span></div>
          },
          {
            Header: "Item",
            accessor: "item",
            width: 200,
            style: {
              textAlign: "left",
              whiteSpace: 'unset'
            },
            Cell: row => <div><span title={row.value}>{row.value}</span></div>
          },

        ]
      },
      {
        Header: "Frequency",
        accessor: "frequency",
        width: 100,
        minwidth: 100,
        maxwidth: 100,
        style: {
          textAlign: "left"
        },
        Cell: row => <div><span title={row.value}>{row.value}</span></div>,
        filterMethod: (filter, row) => {
          if (filter.value === 'all') {
            return row
          }
          else {
            if (row[filter.id] !== null && row[filter.id] !== '') { return row[filter.id].match(filter.value) } else { console.log(row[filter.id]) }
          }

        },
        Filter: ({ filter, onChange }) =>
          customFilterFrequency({ filter, onChange })
      },

      {
        Header: "Description",
        accessor: "description",
        width: 350,
        style: {
          textAlign: "left",
          whiteSpace: 'unset'
        },
        Cell: row => <div dangerouslySetInnerHTML={
          {
            __html: row.value.replace(/\n/g, "<br>")
          }} />
      },
      {
        Header: "Documentation Requirement",
        accessor: "documentation_requirement",
        width: 300,
        style: {
          textAlign: "left",
          whiteSpace: 'unset'
        },
        Cell: row => <div dangerouslySetInnerHTML={
          {
            __html: row.value.replace(/\n/g, "<br>")
          }} title={row.value} />
      },
      {
        Header: "Working Paper Reference",
        accessor: "working_paper_reference",
        width: 200,
        style: {
          textAlign: "left"
        },
        // Cell: (cellinfo, props) => this.renderEditable(cellinfo, cellinfo.original.locked_by)
        Cell: row => <div><span title={row.value}>{row.value}</span></div>
      }
    ];

    const checklistColumnsGlobal = [
      {
        Header: "",
        fixed: "left",
        columns: [
          {
            Header: "",
            id: "row",
            width: 30,
            filterable: false,
            Cell: (row) => {
              return <div>{row.index + 1}</div>;
            }
          },
          {
            Header: "Segment",
            accessor: "id",
            width: 100,
            style: {
              textAlign: "Left",
              whiteSpace: 'unset'
            },
            Cell: row => <div><span title={row.value}>{row.value}</span></div>,
            filterMethod: (filter, row) => {
              if (filter.value === 'all') {
                return row
              }
              else {
                if (row[filter.id] !== null && row[filter.id] !== '') { return row[filter.id].match(filter.value) } else { console.log(row[filter.id]) }
              }
            },
            Filter: ({ filter, onChange }) =>
              customFilterSegmentGlobal({ filter, onChange })
          },
          {
            Header: "Task Group",
            accessor: "task_group",
            width: 150,
            style: {
              textAlign: "Left",
              whiteSpace: 'unset'
            },
            Cell: row => <div><span title={row.value}>{row.value}</span></div>
          },
          {
            Header: "Item",
            accessor: "item",
            width: 200,
            style: {
              textAlign: "left",
              whiteSpace: 'unset'
            },
            Cell: row => <div><span title={row.value}>{row.value}</span></div>
          },

        ]
      },
      {
        Header: "Frequency",
        accessor: "frequency",
        width: 100,
        minwidth: 100,
        maxwidth: 100,
        style: {
          textAlign: "left"
        },
        Cell: row => <div><span title={row.value}>{row.value}</span></div>,
        filterMethod: (filter, row) => {
          if (filter.value === 'all') {
            return row
          }
          else {
            if (row[filter.id] !== null && row[filter.id] !== '') { return row[filter.id].match(filter.value) } else { console.log(row[filter.id]) }
          }

        },
        Filter: ({ filter, onChange }) =>
          customFilterFrequency({ filter, onChange })
      },

      {
        Header: "Description",
        accessor: "description",
        width: 350,
        style: {
          textAlign: "left",
          whiteSpace: 'unset'
        },
        Cell: row => <div dangerouslySetInnerHTML={
          {
            __html: row.value.replace(/\n/g, "<br>")
          }} />
      },
      {
        Header: "Documentation Requirement",
        accessor: "documentation_requirement",
        width: 300,
        style: {
          textAlign: "left",
          whiteSpace: 'unset'
        },
        Cell: row => <div dangerouslySetInnerHTML={
          {
            __html: row.value.replace(/\n/g, "<br>")
          }} title={row.value} />
      },
      {
        Header: "Working Paper Reference",
        accessor: "working_paper_reference",
        width: 200,
        style: {
          textAlign: "left"
        },
        // Cell: (cellinfo, props) => this.renderEditable(cellinfo, cellinfo.original.locked_by)
        Cell: row => <div><span title={row.value}>{row.value}</span></div>
      }
    ];

    const maxPaginationRows = 5000;
    const percentage = 18.5;

    let Total = JSON.parse(JSON.stringify(this.state.checklists))

    return (
      <div className="content" onClick={isMenuExpanded ? menuToggle : null}>
        <div className="fscp-detail">
          <ReactTable
            data={this.state.templateTypes}
            columns={templateColumns}
            defaultPageSize={4}
            minRows={0}
            noDataText={null}
            filterable={false}
            showPageSizeOptions={false}
            showPagination={false}
            className="-highlight"
            expanded={this.state.expandedTemplates}
            onExpandedChange={(newExpanded, index, e, expandedType) => { this.setExpandedRows(newExpanded, index, e) }}
            SubComponent={row => {
              const { checklists } = this.state;
              const checklistGroups = checklists.filter((item) => item.template_type.includes(row.original.template_type));
              return (
                <div style={{ padding: '0px' }}>
                  <ReactTableFixedColumns
                    data={checklistGroups}
                    columns={checklistGroups[0].template_type === "GLOBAL" ? checklistColumnsGlobal : checklistColumns}
                    filterable={true}
                    pageSize={checklistGroups.length}
                    minRows={0}
                    style={
                      { height: "500px" }}
                    showPageSizeOptions={false}
                    showPagination={checklistGroups.length > maxPaginationRows ? true : false}
                    defaultFilterMethod={this.filterCaseInsensitive}
                    className="-striped -highlight"
                  />
                </div>
              )
            }}
          />
        </div>
      </div>
    );
  };
};

export default withCookies(withRouter(globalTemplate));