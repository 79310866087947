/* global gapi */
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import Select from 'react-select';
import ReactTable from "react-table";
import './sea-react-table.css';
//import "react-table/react-table.css";
import { Link, withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import FscpHistory from './FscpHistory';
import ExportHighlightReport from './ExportHighlightReport';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import ExportSummary from './ExportSummary';

const baseURL = `${process.env.REACT_APP_API_URL}`;

const historyStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '50%',
    height: '70%',
    transform: 'translate(-50%, -50%)'
  }
};

const statusStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '22%',
    height: '30%',
    transform: 'translate(-50%, -50%)'
  }
};

const alertCreateStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '22%',
    height: '25%',
    transform: 'translate(-50%, -50%)'
  }
};

Modal.setAppElement('#root');

const selectStyle = {
  container: base => ({
    ...base,
    width: 250,
    minwidth: 250,
    maxwidth: 250,
    height: 38,
    maxheight: 38,
    marginLeft: 15,
  })
};

let Segments = [];
let Entities = [];
let Countries = [];
let Periods = [];
let Statuses = [{ label: "Created", value: "CREATED" },
{ label: "Closed", value: "CLOSED" },
];

class Fscp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accesstoken: '',
      UserID: '',
      username: '',
      cookies: this.props.cookies.get('Logins'),
      checklists: [],
      isSearch: false,
      isCreate: false,
      isChecklistsExist: false,
      selectedPeriod: 'All',
      selectedSegment: 'All',
      selectedCountry: 'All',
      selectedEntity: 'All',
      selectedStatus: null,
      selectedChecklistIndex: null,
      showHistory: false,
      showUpdateStatus: false,
      showCreateAlert: false,
      selectedChecklistId: null,
      entityId: '',
      periodId: null,
      accessType: null,
      render: false,
      checklistsHighlight: [],
      checklistsUpdateStatus: []
    };
    this.fetchSummaryDataHandler = this.fetchSummaryDataHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.toggleButtonHandler = this.toggleButtonHandler.bind(this);
    this.createChecklistHandler = this.createChecklistHandler.bind(this);
    this.openHistory = this.openHistory.bind(this);
    this.afterOpenHistory = this.afterOpenHistory.bind(this);
    this.closeHistory = this.closeHistory.bind(this);
    this.openUpdateStatus = this.openUpdateStatus.bind(this);
    this.afterOpenUpdateStatus = this.afterOpenUpdateStatus.bind(this);
    this.closeUpdateStatus = this.closeUpdateStatus.bind(this);
    this.openCreateAlert = this.openCreateAlert.bind(this);
    this.afterOpenCreateAlert = this.afterOpenCreateAlert.bind(this);
    this.closeCreateAlert = this.closeCreateAlert.bind(this);
    this.toggleShowModalHandler = this.toggleShowModalHandler.bind(this);
    this.toggleUpdateStatusHandler = this.toggleUpdateStatusHandler.bind(this);
    this.validateUpdateStatus = this.validateUpdateStatus.bind(this);
  };

  abortController = new AbortController();

  getPrevPeriodName = (n) => {
    let sysDate = new Date();
    sysDate.setMonth(sysDate.getMonth() + n);
    return sysDate.toLocaleString('default', { month: 'short' }) + "-" + sysDate.getFullYear();
  }

  onChangeHandler = (selectedOption, compProps) => {
    const selectedValue = selectedOption.value, name = compProps.name;
    const { isSearch, selectedPeriod, selectedEntity, selectedCountry, selectedSegment } = this.state;
    if (isSearch && (selectedPeriod !== selectedValue || selectedEntity !== selectedValue || selectedValue === 'All')) {
      this.setState({ isSearch: false });
    }
    switch (name) {
      case 'period':
        this.setState({ selectedPeriod: selectedValue, periodnameexport: selectedOption.label, checklistsHighlight: [], checklistsUpdateStatus: [], searchexport: false, searchexportStatus: false });
        //this.fetchHighlightReport(selectedValue)
        console.log(selectedValue)
        break;
      case 'segment':
        this.setState({ selectedSegment: selectedValue },
          () => {
            this.cleanUpDropdown('country');
            this.cleanUpDropdown('entity');
            this.populateCountries(selectedEntity, selectedCountry, selectedValue);
            this.populateEntities(selectedCountry, selectedValue);
          });
        break;
      case 'country':
        this.setState({ selectedCountry: selectedValue },
          () => {
            this.cleanUpDropdown('segment');
            this.cleanUpDropdown('entity');
            this.populateSegments(selectedEntity, selectedValue);
            this.populateEntities(selectedValue, selectedSegment);
          });
        break;
      case 'entity':
        this.setState({ selectedEntity: selectedValue },
          () => {
            this.cleanUpDropdown('segment');
            this.cleanUpDropdown('country');
            this.populateCountries(selectedValue, selectedCountry, selectedSegment);
            this.populateSegments(selectedValue, selectedCountry);
          });
        break;
      default:
        break;
    };
  };

  toggleButtonHandler = () => {
    const { checklists, isSearch, isChecklistsExist, selectedPeriod, selectedEntity } = this.state;
    let disabled = false;
    if ((!isChecklistsExist && !isSearch) ||
      (isChecklistsExist && selectedPeriod === checklists[0].period_id && selectedEntity === checklists[0].entity_id) ||
      (isChecklistsExist && !isSearch && (selectedPeriod !== checklists[0].period_id || selectedEntity !== checklists[0].entity_id)) ||
      (selectedEntity === 'All') || (this.state.accesstype.toUpperCase() === "INQUIRY")
    ) {
      disabled = true;
    }
    ;
    return disabled;
  };

  fetchSummaryDataHandler() {
    const { isCreate, selectedPeriod, selectedEntity, selectedSegment, selectedCountry, accesstoken } = this.state;
    const url = `${baseURL}/fscp/checklist_summary/?period_id=${selectedPeriod}&entity_id=${selectedEntity}&country_id=${selectedCountry}&segment_id=${selectedSegment}`;
    this.abortController.abort();
    this.abortController = new AbortController();
    const signal = this.abortController.signal;
    this.setState({ checklistsHighlight: [], checklistsUpdateStatus: [] })
    this.setState({ searchexport: true, searchexportStatus: true })
    this.fetchHighlightReport(selectedPeriod, selectedEntity)
    this.fetchStatusUpdateReport(selectedPeriod)
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {

        if(jsonStr.detail === 'You do not have permission to perform this action.'){
          return
        }
        
        this.setState({ checklists: jsonStr, isSearch: true, isChecklistsExist: jsonStr.length > 0 ? true : false });

        if (isCreate) {
          this.openCreateAlert();
        }

        this.state.checklists.map(item => {
          const url = `${baseURL}/fscp/my_entity_access/?entity_id=${item.entity_id}`;
          fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer google-oauth2 ${accesstoken}`
            }
          })
            .then(response => response.json())
            .then((json) => {
              // console.log("Access_Type ",json)
              // console.log("Access_Type ",json[0].access_type)
              let saveState = this.state.checklists
              let clidIndex = saveState.map((item) => { return item.checklist_id; }).indexOf(item.checklist_id);
              this.setState(prevState => {
                const newchecklists = [...prevState.checklists];
                newchecklists[clidIndex].access = json[0].access_type
                return { checklists: newchecklists };
              })
              // console.log("checklist", this.state.checklists)
            })
            .catch(error => console.log(error))
        })
      });
  };

  fetchHighlightReport(period, entityId) {
    //const {periodid,accesstoken} = this.props;    
    const url = `${baseURL}/fscp/checklist_by_period/?period_id=${period}&entity_id=${entityId}`;
    fetch(url, {
      signal: this.abortController.signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.setState({ checklistsHighlight: [] })
        if (jsonStr.length > 0) {
          // console.log("fetch highlight",jsonStr)
          this.setState({ checklistsHighlight: jsonStr })
        }
        else { this.setState({ checklistsHighlight: [], searchexport: false }) }

      });
  };

  fetchStatusUpdateReport(period) {
    const url = `${baseURL}/fscp/checklist_summary_report/?period_id=${period}`;
    fetch(url, {
      signal: this.abortController.signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.setState({ checklistsUpdateStatus: [] })
        if (jsonStr.length > 0) {
          // console.log("fetch highlight",jsonStr)
          this.setState({ checklistsUpdateStatus: jsonStr })
        }
        else { this.setState({ checklistsUpdateStatus: [], searchexportStatus: false }) }

      });
  };

  toggleUpdateStatusHandler = (selectedChecklistIndex) => {
    const { checklists } = this.state;
    if (checklists[selectedChecklistIndex].access === "HQ" && checklists[selectedChecklistIndex].status.toUpperCase() === "APPROVED") {
      return false;
    }
    else {
      return true;
    };
  };

  createChecklistHandler = () => {
    const { UserID, selectedPeriod, selectedEntity, accesstoken } = this.state;
    const url = `${baseURL}/fscp/checklist_header/`;
    const signal = this.abortController.signal;
    fetch(url, {
      signal: signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      },
      body: JSON.stringify({ period_id: selectedPeriod, entity_id: selectedEntity, status: 'Created', created_by: UserID[0].id })
    }).then(response => response.json())
      .then((jsonStr) => {
        this.setState({ isCreate: !!(jsonStr.checklist_id) ? true : false });
        this.fetchSummaryDataHandler();
      })
      .catch(error => console.log(error));
  };

  populatePeriods() {
    const url = `${baseURL}/fscp/periods/`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    const def_PeriodName = this.getPrevPeriodName(-1);
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          if (def_PeriodName === element["month"] + "-" + element["year"]) {
            this.setState({ selectedPeriod: element["period_id"], periodnameexport: element["period_name"] });
            //this.fetchHighlightReport(element["period_id"])
          }
          Periods.push({ label: element["month"] + "-" + element["year"], value: element["period_id"] });

        });
      });
  };

  populateCountries(selectedEntity, selectedCountry, selectedSegment) {
    const url = `${baseURL}/fscp/country_list/?entity_id=${selectedEntity}&country_id=${selectedCountry}&segment_id=${selectedSegment}`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Countries.push({ label: element["country_name"], value: element["country_id"] });
        });
      });
  };

  populateSegments(selectedEntity, selectedCountry) {
    const url = `${baseURL}/fscp/segment_list/?entity_id=${selectedEntity}&country_id=${selectedCountry}`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Segments.push({ label: element["segment_name"], value: element["segment_id"] });
        })
      });
  };

  populateEntities(selectedCountry, selectedSegment) {
    const url = `${baseURL}/fscp/entity_list/?country_id=${selectedCountry}&segment_id=${selectedSegment}`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Entities.push({ label: element["entity_id"], value: element["entity_id"] });
        })
      });
  };

  cleanUpDropdown = (type) => {
    switch (type) {
      case 'All':
        Periods.length = 0;
        Countries.length = 0;
        Segments.length = 0;
        Entities.length = 0;
        Countries.push({ label: "All", value: "All" });
        Segments.push({ label: "All", value: "All" });
        Entities.push({ label: "All", value: "All" });
        break;
      case 'segment':
        Segments.length = 0;
        Segments.push({ label: "All", value: "All" });
        break;
      case 'country':
        Countries.length = 0;
        Countries.push({ label: "All", value: "All" });
        break;
      case 'entity':
        Entities.length = 0;
        Entities.push({ label: "All", value: "All" });
        break;
      default:
        break;
    };
  }

  getuserid = () => {
    const { accesstoken } = this.state;
    const url = `${baseURL}/fscp/current_user/`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((json) => {
        this.setState({ UserID: json });
      })
      .catch(error => console.log(error));
  };

  toggleShowModalHandler = (selectedChecklistIndex, type) => {
    this.setState(prevState => {
      const newchecklists = [...prevState.checklists];
      switch (type) {
        case 'History':
          newchecklists[selectedChecklistIndex].showHistory = !newchecklists[selectedChecklistIndex].showHistory;
          break;
        case 'UpdateStatus':
          newchecklists[selectedChecklistIndex].showUpdateStatus = !newchecklists[selectedChecklistIndex].showUpdateStatus;
          break;
        default:
          break;
      };
      return { checklists: newchecklists };
    });
  };

  openHistory = (row) => {
    const { entity_id, period_id } = row.original;
    this.toggleShowModalHandler(row.index, 'History');
    this.setState({ selectedChecklistIndex: row.index, entityId: entity_id, periodId: period_id });
  };

  afterOpenHistory = () => {
    // references are now sync'd and can be accessed.
    const { style } = this.subtitle;
    style.color = '#3d6ed1';
    style.textAlign = 'Center';
    style.borderBottom = '1px solid #cfcfcf';
  };

  closeHistory = () => {
    const { selectedChecklistIndex } = this.state;
    this.toggleShowModalHandler(selectedChecklistIndex, 'History');
    this.setState({ selectedChecklistIndex: null, entity_id: null, periodId: null });
  };

  validateUpdateStatus = (row) => {
    const { checklists, accesstoken } = this.state;
    const selectedChecklistIndex = row.index;
    console.log("selectedChecklistIndex ", selectedChecklistIndex, "checklists ", checklists,
      "status ", row.original.status);
    const url = `${baseURL}/fscp/checklist_header/${row.original.checklist_id}/`;
    const signal = this.abortController.signal;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        console.log("JsonStr ", jsonStr, " status ", row.original.status, " json status ", jsonStr.status);
        if (row.original.status === jsonStr.status) {
          this.openUpdateStatus(row);
        }
        else {
          alert("Status has change, click ok to refresh")
          this.setState(prevState => {
            const updatedChecklists = [...prevState.checklists];
            updatedChecklists[selectedChecklistIndex].status = jsonStr.status;
            return { checklists: updatedChecklists };
          })
        }
      })
      .catch(error => console.log(error));
  };

  openUpdateStatus = (row) => {
    const { entity_id, period_id } = row.original;
    this.toggleShowModalHandler(row.index, 'UpdateStatus');
    this.setState({ selectedChecklistIndex: row.index, entityId: entity_id, periodId: period_id });
  };

  afterOpenUpdateStatus = () => {
    //references are now sync'd and can be accessed.f
    const { style } = this.subtitle;
    style.color = '#3d6ed1';
    style.textAlign = 'Center';
    style.borderBottom = '1px solid #cfcfcf';
  };

  closeUpdateStatus = (statusType) => {
    if (statusType === "Cancel") {
      const { selectedChecklistIndex } = this.state;
      this.toggleShowModalHandler(selectedChecklistIndex, 'UpdateStatus');
      this.setState({ selectedChecklistIndex: null, entity_id: null, periodId: null, selectedStatus: null })
    }
    else {
      this.updateChecklistStatus();
    }
  };

  openCreateAlert = () => {
    const { checklists } = this.state;
    const checklist_id = checklists.map((checklist) => checklist.checklist_number);
    this.setState({ showCreateAlert: true, selectedChecklistId: checklist_id });
  };

  afterOpenCreateAlert = () => {
    // references are now sync'd and can be accessed.
    const { style } = this.subtitle;
    style.color = '#3d6ed1';
    style.fontSize = '16px';
    style.textAlign = 'Center';
  };

  closeCreateAlert = () => {
    const { selectedPeriod, selectedEntity, checklists, selectedChecklistId } = this.state;
    //this.setState({showCreateAlert: false, isCreate : false, selectedChecklistId : null});
    this.setState({ showCreateAlert: false, isCreate: false });
    const periodName = checklists[0].month + "-" + checklists[0].year;
    const status = checklists[0].status;
    // console.log("inside closeCreateAlert ");
    // console.log("status ", status);

    this.props.history.push({
      pathname: `/fscp/checklist/${checklists[0].checklist_id}`,
      state: {
        period_id: selectedPeriod,
        period_name: periodName,
        entity_id: selectedEntity,
        status: status,
        checklistnumber: selectedChecklistId
      }
    });
  };

  updateChecklistStatus = () => {
    const { accesstoken, UserID, checklists, selectedChecklistIndex, entityId, periodId, selectedStatus } = this.state;
    // console.log("inside updateChecklistStatus");
    // console.log("userid ",this.state.UserID[0]["id"], "status ", selectedStatus, 
    // "selectedChecklistId ", checklists[selectedChecklistIndex].checklist_id, "entity_id ", entityId, " period_id ", periodId
    // );
    const url = `${baseURL}/fscp/checklist_header/${checklists[selectedChecklistIndex].checklist_id}/`;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      },
      body: JSON.stringify({
        period_id: periodId,
        entity_id: entityId,
        last_updated_by: UserID[0]["id"],
        status: selectedStatus
      })
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.setState({ selectedChecklistIndex: null, showUpdateStatus: false, entity_id: null, periodId: null, selectedStatus: null },
          () => {
            this.fetchSummaryDataHandler()
          })
      })
      .catch(error => console.log(error));
  };



  getaccesstype = () => {
    const { accesstoken, entityId } = this.state
    // console.log(props.original.checklist_line_id)
    const url = `${baseURL}/fscp/my_entity_access/?entity_id=${entityId}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((json) => {
        //console.log("Access_Type ",json)
        //console.log("Access_Type ",json[0].access_type)
        this.setState({ accesstype: json[0].access_type });
      })
      .catch(error => console.log(error))
  }

  componentDidMount() {
    const { selectedSegment, selectedCountry, selectedEntity } = this.state;
    this.cleanUpDropdown('All');
    //console.log("clientid",process.env.REACT_APP_CLIENT_ID,"url",process.env.REACT_APP_API_URL)
    if (this.state.cookies !== "Test" && this.state.cookies) {
      window.gapi.load('auth2', () => {
        window.gapi.auth2.init({
          //client_id: '484166416946-dkll31nh5n87c6so4fdh6nj7nv655ve3.apps.googleusercontent.com', //server
          client_id: `${process.env.REACT_APP_CLIENT_ID}`,
          //client_id: '70112657995-l0qtjtojb4efi2dhi5jt8u93gqgo579m.apps.googleusercontent.com', //local
          cookie_policy: 'none'
        }).then(() => {
          try {
            console.log("test", gapi.auth2.getAuthInstance().currentUser.listen((usr) => console.log('listen2', gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token)))
            const user = gapi.auth2.getAuthInstance().currentUser.get();
            console.log("test2", user.reloadAuthResponse())
            // gapi.auth2.getAuthInstance().currentUser.listen((user)=>  this.setState({ accesstoken:gapi.auth2.getAuthInstance().currentUser.listen((user)=> console.log("listen",user)).dZ.je.tc.access_token}))
            gapi.auth2.getAuthInstance().currentUser.listen((usr) => this.setState({ accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() }))

            //gapi.auth2.getAuthInstance().currentUser.listen((user)=>console.log("user",user))
          } catch (error) {
            console.log("trycatch", error)
          }
          this.setState({ accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() })
          console.log(this.state.accesstoken, this.state.username)
          console.log(gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail())
          this.getuserid();
          this.getaccesstype();
          this.populatePeriods();
          this.populateCountries(selectedEntity, selectedCountry, selectedSegment);
          this.populateSegments(selectedEntity, selectedCountry);
          this.populateEntities(selectedCountry, selectedSegment);

        })
      })
    } else {
      gapi.auth2.getAuthInstance().disconnect()
      // window.location.href=`${process.env.REACT_APP_REDIRECTLOGIN_URI}`;
      window.location.href = `${process.env.REACT_APP_REDIRECT_URI}`;
    }
  };

  componentWillUnmount() {
    this.abortController.abort();
  }



  render() {
    const columns = [
      {
        Header: 'No',
        width: 80,
        color: 'blue',
        Cell: (props) => {
          return props.row._index + 1
        },
      },
      {
        Header: "Checklist ID",
        accessor: "checklist_number",
        width: 270,/*cakrab*/
        minwidth: 270,/*cakrab*/
        maxwidth: 270,/*cakrab*/
        color: "blue",
        Cell: props => {
          // let titleTooltip = (props.original.status !== 'Created' ?  null : "Click here to open the checklist details");
          const titleTooltip = "Click here to open the checklist details";
          // {props.original.status === 'Created' ? 
          // : '#'
          // }
          // className = {props.original.status !== 'Created' ? "disableHover" : "enableHover"}  
          return (
            <Link to={{
              pathname: `/fscp/checklist/${props.original.checklist_id}`,
              state: {
                period_id: props.original.period_id,
                period_name: props.original.month + "-" + props.original.year,
                entity_id: props.original.entity_id,
                status: props.original.status,
                checklistnumber: props.original.checklist_number
              }
            }
            }
            ><span className="font-blue" title={titleTooltip}>{props.value}</span>
            </Link>
          )
        },
        style: {
          textAlign: "left", /*cakrab*/
          paddingLeft: 15, /*cakrab*/
          textDecoration: "underline"
        }
      },
      {
        Header: "Period Name",
        id: "period",
        accessor: props => `${props.month}-${props.year}`,
        width: 180,
        minwidth: 270,/*cakrab*/
        maxwidth: 270,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        }
      },
      {
        Header: "Entity",
        accessor: "entity_id",
        width: 180,
        minwidth: 270,/*cakrab*/
        maxwidth: 270,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        }
      },
      {
        Header: "Status",
        id: "status",
        accessor: props => `${props.status.charAt(0).toUpperCase()}${props.status.slice(1).toLowerCase()}`,
        width: 150,
        minwidth: 270,/*cakrab*/
        maxwidth: 270,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        }
      },
      {
        Header: "Prepared",
        //accessor:"countprepared",
        filterable: false,
        sortable: false,
        width: 100,
        style: {
          padding: 3,
        },
        Cell: props => {
          return <Progress percent={Math.round(props.original.prepared_percentage)} />
        }
      },
      {
        Header: "Reviewed",
        filterable: false,
        sortable: false,
        width: 100,
        style: {
          padding: 3,
        },
        Cell: props => {
          return <Progress percent={Math.round(props.original.reviewed_percentage)} />
        }
      },
      {
        Header: "",
        filterable: false,
        sortable: false,
        width: 120, /*cakrab*/
        minwidth: 120,/*cakrab*/
        maxwidth: 120,/*cakrab*/
        style: {
          padding: 3
        },
        Cell: (row) => {
          const { checklists } = this.state;
          return (
            <div>
              <button className="fscp-showhistory-btn btn-focus"
                onClick={(e) => this.openHistory(row)}
              >
                Show History
              </button>
              {checklists[row.index].showHistory &&
                <Modal
                  isOpen={checklists[row.index].showHistory}
                  onAfterOpen={this.afterOpenHistory}
                  onRequestClose={this.closeHistory}
                  style={historyStyles}
                >

                  <h2 ref={subtitle => this.subtitle = subtitle}>History of Checklist {checklists[row.index].checklist_number}</h2>
                  <FscpHistory checklistId={checklists[row.index].checklist_id} accesstoken={this.state.accesstoken} /><br />
                  <div className="fscp-history-wrapper">
                    <button className="fscp-history-btn btn-neutral" onClick={this.closeHistory}>Close</button>
                  </div>
                </Modal>}
            </div>
          )
        }
      },
      {
        Header: "",
        filterable: false,
        sortable: false,
        width: 120, /*cakrab*/
        minwidth: 120,/*cakrab*/
        maxwidth: 120,/*cakrab*/
        style: {
          padding: 3,
        },
        Cell: row => {
          const { checklists } = this.state;
          return (
            <div>
              <button className={this.toggleUpdateStatusHandler(row.index) ? "fscp-updstat-btn-disabled btn-focus" : "fscp-updstat-btn btn-focus"} onClick={(e) => this.validateUpdateStatus(row)}
                disabled={this.toggleUpdateStatusHandler(row.index)}
              >
                Update Status
              </button>
              {checklists[row.index].showUpdateStatus &&
                <Modal
                  isOpen={checklists[row.index].showUpdateStatus}
                  onAfterOpen={this.afterOpenUpdateStatus}
                  onRequestClose={this.closeUpdateStatus}
                  style={statusStyles}
                >
                  <h2 ref={subtitle => this.subtitle = subtitle}>Update Status for Checklist {checklists[row.index].checklist_number}</h2>
                  <div className="fscp-updstat">
                    <Select
                      styles={selectStyle}
                      options={Statuses}
                      placeholder={'Status'}
                      name='status'
                      onChange={(e) => this.state.selectedStatus = e.value}
                    />
                  </div><br /><br />
                  <div className="fscp-updstat-btn-wrapper">
                    <button className="fscp-updstat-btn btn-focus" onClick={(e) => this.closeUpdateStatus("Update")}>Ok</button>
                    <button className="fscp-updstat-btn btn-focus" onClick={(e) => this.closeUpdateStatus("Cancel")}>Cancel</button>
                  </div>
                </Modal>}
            </div>
          )
        }
      },
    ];

    const { selectedPeriod } = this.state.selectedPeriod;
    const { selectedSegment } = this.state.selectedSegment;
    const { selectedCountry } = this.state.selectedCountry;
    const { selectedEntity } = this.state.selectedEntity;
    const { isMenuExpanded, menuToggle } = this.props;

    const exportActiveUSer = () => {
      const url = `${baseURL}/fscp/export_user_list/`;

      setTimeout(() => {
        const response = {
          "file": url,
        };
        window.open(response.file);
      }, 100);
    }

    return (
      <div className="content" onClick={isMenuExpanded ? menuToggle : null}>
        <div className="fscp-container" style={{ height: '100%' }}>
          <div className="fscp-wrapper">
            <h1 id='title'>Summary</h1>
            {/* {this.state.selectedPeriod==="All"?null:this.fetchHighlightReport()} */}
            <div className="fscp-search">
              <Select
                styles={selectStyle}
                options={Periods}
                placeholder={'Period'}
                name='period'
                value={selectedPeriod}
                onChange={this.onChangeHandler}
                defaultValue={{ label: this.getPrevPeriodName(-1), value: this.state.selectedPeriod }}
              />
              <Select
                styles={selectStyle}
                options={Countries}
                placeholder={'Country'}
                name='country'
                value={selectedCountry}
                onChange={this.onChangeHandler}
              />
              <Select
                styles={selectStyle}
                options={Segments}
                placeholder={'Segment'}
                name='segment'
                value={selectedSegment}
                onChange={this.onChangeHandler}
              />
              <Select
                styles={selectStyle}
                options={Entities}
                placeholder={'Entity'}
                name='entity'
                value={selectedEntity}
                onChange={this.onChangeHandler}
              />
              <div className="fscp-btn-wrapper">
                <button className="fscp-btn btn-focus" onClick={this.fetchSummaryDataHandler}>Search</button>
                <button className={this.toggleButtonHandler() ? "fscp-btn-disabled btn-focus" : "fscp-btn btn-focus"} disabled={this.toggleButtonHandler()} onClick={this.createChecklistHandler}>Create</button>
                {this.state.isCreate &&
                  <Modal
                    isOpen={this.state.showCreateAlert}
                    onAfterOpen={this.afterOpenCreateAlert}
                    onRequestClose={this.closeCreateAlert}
                    style={alertCreateStyles}
                  >
                    <h2 ref={subtitle => this.subtitle = subtitle}>
                      Checklist {this.state.selectedChecklistId} has been created successfully.<br />
                      Click [Ok] to enter the details.
                  </h2>
                    <br />
                    <div className="fscp-createalert-wrapper">
                      <button className="fscp-createalert-btn btn-focus" onClick={this.closeCreateAlert}>Ok</button>
                    </div>
                  </Modal>}
                {this.state.accesstoken.length > 0 && this.state.selectedPeriod !== "All" ? <ExportHighlightReport
                  periodid={this.state.selectedPeriod}
                  accesstoken={this.state.accesstoken}
                  periodName={this.state.periodnameexport}
                  checklists={this.state.checklistsHighlight}
                  searchexport={this.state.searchexport}
                /> : null}
                {this.state.accesstoken.length > 0 && this.state.selectedPeriod !== "All" ? <ExportSummary
                  periodid={this.state.selectedPeriod}
                  accesstoken={this.state.accesstoken}
                  periodName={this.state.periodnameexport}
                  checklists={this.state.checklistsUpdateStatus}
                  searchexport={this.state.searchexportStatus}
                  accessType={this.state.accesstype !== undefined ? this.state.accesstype.toUpperCase() : ""}
                /> : null}
                {this.state.accesstype == "HQ" &&
                  <button className="fscp-btn btn-focus" onClick={() => exportActiveUSer()}>Export User</button>
                }
              </div>

            </div>
            <div className="fscp-summary">
              <ReactTable
                columns={columns}
                data={this.state.checklists}
                //defaultPageSize={8}//cakrab
                filterable={this.state.checklists.length > 1 ? true : false}
                showPageSizeOptions={false}
                className="-highlight"
                minRows={this.state.checklists.length > 1 ? 0 : 1}
                showPagination={this.state.checklists.length > 15 ? true : false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default withCookies(withRouter(Fscp));
