import React from 'react';
import Menulist from '../menulist/Menulist';
import { Link, withRouter } from 'react-router-dom';

const getMenuTitle = props => {
    let menuTitle = props.location.pathname;
    if (menuTitle.indexOf("/") !== -1) {
        if (menuTitle.length === 1) {
            menuTitle = "Home";
        }
        else if (menuTitle === "/globalTemplate") {
            menuTitle = "Global Template";
        }
        else {
            menuTitle = menuTitle.split("/");
            menuTitle = menuTitle[1];
            menuTitle = menuTitle.toUpperCase();
        }
    }
    return menuTitle;
}

const Menubar = props => {
    return (
        <div className="menubar" onClick={props.isMenuExpanded ? props.menuToggle : null}>
            <div className="menu-title">
                <Link to={props.isMenuExpanded ? '/' : props.location.pathname} onClick={props.menuToggle}>
                    <span className="sprite-more fl"></span>{getMenuTitle(props)}
                </Link>
                <Menulist isMenuExpanded={props.initMenu} menuToggle={props.menuToggle} />
            </div>
        </div>
    );
};

export default withRouter(Menubar);
