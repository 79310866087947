import React, {Component} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
 
class ExportFscpDetail extends Component {
    renderTableHeader() {
        // const {checklists} = this.props;
        // let header = Object.keys(checklists[0])
        // console.log("header",header)
        // return header.map((key, index) => {
        //     return  <th key={index}>
        //                 {key.toLowerCase().replace(/_/g, " ")
        //                                   .split(' ').map((c)=> c.charAt(0).toUpperCase() + c.substring(1))
        //                                   .join(' ')
        //                 }
        //             </th>
        // })
        // ChecklistId	Checklist Line Id	Template Id	Template Type	Line Id	Task Group	Item	Frequency	Description	Documentation Requirement	Working Paper Reference	Prepared By	Prepared By User	Prepared Date	Reviewed By User	Reviewed By	Reviewed Date	Conclusion	Matters To Highlight	Action Plan	Control	Key Report	Status	Locked By
        
        return (
        <>
            <th key="checklist number">checklist number</th>
            {/* <th key="checklist line id">checklist line id</th> */}
            {/* <th key="template id">template id</th> */}
            <th key="template type">template type</th>
            <th key="line id">line id</th>
            <th key="task group">task group</th>
            <th key="item">item</th>
            <th key="frequency">frequency</th>
            <th key="description">description</th>
            <th key="documentation requirement">documentation requirement</th>
            <th key="working paper reference">working paper reference</th>
            {/* <th key="prepared by">prepared by</th> */}
            <th key="prepared by user">prepared by user</th>
            <th key="prepared date">prepared date</th>
            <th key="reviewed by user">reviewed by user</th>
            {/* <th key="reviewed by">reviewed by</th> */}
            <th key="reviewed date">reviewed date</th>
            <th key="conclusion">conclusion</th>
            <th key="matters to highlight">matters to highlight</th>
            {/* <th key="action plan">action plan</th> */}
            <th key="control">control</th>
            <th key="key report">key report</th>
            <th key="status">status</th>
            {/* <th key="locked by">locked by</th>
            <th key="locked by user">locked by user</th> */}
        </>
        )
    }

    setHTML(props){
        return props?{__html: props.replace(/<div>/g, "<br style=mso-data-placement:same-cell; />").replace(/<\/div>/g,"").replace(/<br>/g, "<br style=mso-data-placement:same-cell; />")}:{__html: props}
    }

    renderTableData() {
        const {checklists,checklistnumber} = this.props;
        return checklists.map((checklist) => {
           const { checklist_id, checklist_line_id,
             template_id, template_type, line_id, task_group, item, frequency, description, documentation_requirement, 
                   working_paper_reference, prepared_by, prepared_by_user ,prepared_date, reviewed_by, reviewed_by_user, reviewed_date, conclusion, matters_to_highlight,
                //    action_plan,
                    control, key_report, status
                    //, locked_by, locked_by_user
           } = checklist;
           return (
              <tr key={checklist_line_id}>
                 <td>{checklistnumber}</td>
                 {/* <td>{checklist_line_id}</td> */}
                 {/* <td>{template_id}</td> */}
                 <td>{template_type.charAt(0).toUpperCase() + template_type.slice(1).toLowerCase() + ' Specific'}</td>
                 <td>{line_id}</td>
                 <td>{task_group}</td>
                 <td>{item}</td>
                 <td>{frequency}</td>
                 <td>{description}</td>
                 {/* <td>{documentation_requirement}</td> */}
                 <td dangerouslySetInnerHTML={this.setHTML(documentation_requirement)}/>
                 <td dangerouslySetInnerHTML={this.setHTML(working_paper_reference)}/>
                 {/* <td>{prepared_by}</td> */}
                 <td>{prepared_by_user}</td>
                 <td>{prepared_date}</td>
                 <td>{reviewed_by_user}</td>
                 {/* <td>{reviewed_by}</td> */}
                 <td>{reviewed_date}</td>
                 <td dangerouslySetInnerHTML={this.setHTML(conclusion)}/>
                 <td dangerouslySetInnerHTML={this.setHTML(matters_to_highlight)}/>
                 {/* <td>{action_plan}</td> */}
                 <td dangerouslySetInnerHTML={this.setHTML(control)}/>
                 <td dangerouslySetInnerHTML={this.setHTML(key_report)}/>
                 <td>{status}</td>
                 {/* <td>{locked_by}</td>
                 <td>{locked_by_user}</td> */}
              </tr>
           )
        })
    }

    render() {
        //console.log("ch",this.props.checklists)
        const {entityId, periodName, checklistId, checklistnumber} = this.props;    
        const fileName = `FscpDetail-${entityId}-${periodName}`;
        return (
            <>
                <ReactHTMLTableToExcel
                    id="FscpDetail-Export"
                    className="fscpdetail-btn btn-focus"
                    table="FscpDetail-Table"
                    filename={fileName}
                    sheet={fileName}
                    buttonText="Export"/>
                <table hidden id="FscpDetail-Table">
                    <tbody>
                        <tr>
                            <td>
                                <strong>Entity</strong><br/>
                                <strong>Period</strong><br/>
                                <strong>CheckList Number</strong><br/>
                            </td>
                            <td>
                                <strong> : </strong><br/>
                                <strong> : </strong><br/>
                                <strong> : </strong><br/>
                            </td>
                            <td>
                                {entityId}<br/>
                                {periodName}<br/>
                                {checklistnumber}<br/>
                            </td>
                        </tr>
                        <tr>
                            <td><br/>
                            </td>
                        </tr>    
                        <tr>{this.renderTableHeader()}</tr>
                        {this.renderTableData()}
                    </tbody>
                </table>
            </>
        );
    }
}
 
export default ExportFscpDetail;