import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Login from './components/Login';
import Redirect from './components/Redirect';
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'


const routing = (
    <CookiesProvider>
        <BrowserRouter>
            <Route exact path="/Login" component={Login} />
            <Route exact path="/" component={App} />
            <Route exact path="/fscp" component={App} />
            <Route exact path="/redirect" component={Redirect} />
            <Route exact path='/fscp/checklist/:checklistId' component={App}
            />
            <Route exact path="/globalTemplate" component={App} />
        </BrowserRouter>
    </CookiesProvider>
)


ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
