import React, { Component } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const baseURL = `${process.env.REACT_APP_API_URL}`;

class ExportSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checklists: [],
        }
    }

    componentDidMount() {

    }

    renderTableHeader() {
        return (
            <>
                <th key="Checklist ID">Checklist ID</th>
                <th key="Country">Country</th>
                <th key="Entity">Entity</th>
                <th key="Status">Status</th>
                <th key="Prepared">Prepared (%)</th>
                <th key="Reviewed">Reviewed (%)</th>
                <th key="Preparer">Preparer</th>
                <th key="Preparer Email">Preparer Email</th>
                <th key="Reviewer">Reviewer</th>
                <th key="Reviewer Email">Reviewer Email</th>
            </>
        )
    }

    setHTML(props) {
        return props ? { __html: props.replace(/<div>/g, "<br style=mso-data-placement:same-cell; />").replace(/<\/div>/g, "").replace(/<br>/g, "<br style=mso-data-placement:same-cell; />") } : { __html: props }
    }

    renderTableData() {
        const { checklists, periodName } = this.props;
        return checklists.map((checklist) => {
            const { checklist_number, entity_id, status, prepared_percentage, reviewed_percentage, country_id, preparer, preparer_user, reviewer, reviewer_user
            } = checklist;
            return (
                <tr key={checklist_number}>
                    <td>{checklist_number}</td>
                    <td>{country_id}</td>
                    <td>{entity_id}</td>
                    <td>{status}</td>
                    <td>{prepared_percentage}</td>
                    <td>{reviewed_percentage}</td>
                    <td>{preparer_user}</td>
                    <td>{preparer}</td>
                    <td>{reviewer_user}</td>
                    <td>{reviewer}</td>
                </tr>
            )
        })
    }


    render() {
        const { periodName, checklists, searchexport, accessType } = this.props;
        const exportDate = new Date().toLocaleDateString();
        const fileName = `StatusUpdateReport-${exportDate}`;
        const { periodid, accesstoken } = this.props;
        return (
            <>
                {checklists.length > 0 && accessType === "HQ" ?
                    <ReactHTMLTableToExcel
                        id="Fscp-Export-Summary"
                        className={"fscp-btn-report"}
                        table="Fscp-TableSummary"
                        filename={fileName}
                        sheet={fileName}
                        onClick={e => console.log(e)}
                        buttonText={`${periodName} Status Report`} />

                    : searchexport && accessType === "HQ" ? `Generating report…` : null}
                <table hidden id="Fscp-TableSummary">
                    <tbody>
                        <tr>
                            <td>
                                <strong>Period</strong><br />
                            </td>
                            <td>
                                <strong> : </strong><br />
                            </td>
                            <td>
                                {periodName}<br />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Exported Date</strong><br />
                            </td>
                            <td>
                                <strong> : </strong><br />
                            </td>
                            <td>
                                {new Date().toLocaleString()}<br />
                            </td>
                        </tr>
                        <tr>
                            <td><br />
                            </td>
                        </tr>
                        <tr>{checklists.length > 0 && checklists ? this.renderTableHeader() : null}</tr>
                        {checklists.length > 0 && checklists ? this.renderTableData() : null}
                    </tbody>
                </table>
            </>
        );
    }
}

export default ExportSummary;