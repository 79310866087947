import React, { Component } from 'react';
import ReactTable from "react-table";
//import "react-table/react-table.css";
import './sea-react-table.css'; //cakrab
import {withRouter} from 'react-router-dom';
import {withCookies} from 'react-cookie';
import moment from 'moment';

const historyColumns = [
    { Header: "History Id",
        accessor: "history_id",
        width: 80,
        minwidth: 80,
        maxwidth: 80,
        style: {
            textAlign: "Right"
        },
    },
    { Header: "Action By",
        accessor: "action_by_user",
        width: 150,
        minwidth: 150,
        maxwidth: 150,
        style: {
            textAlign: "Left"
        },
    },        
    {   id: "action_date",
        Header: "Action Date",
        accessor: d=> {
          return moment.utc(d.action_date).format("DD-MMM-YYYY hh:mm:ss A")
        },
        width: 180,
        minwidth: 180,
        maxwidth: 180,
        style: {
            textAlign: "Center"
        }
    },
    { Header: "Description",
        accessor: "action",
        width: 200,
        minwidth: 200,
        maxwidth: 200,
        style: {
            textAlign: "Left"
        },
    }
]

const maxPaginationRows = 8;

class FscpHistory extends Component {    
  constructor(props) {
      super(props);
      this.state = {
        histories:[]
      }
      this.fetchHistoryHandler = this.fetchHistoryHandler.bind(this);   
  }

  abortController = new AbortController();

  fetchHistoryHandler = (checklistId) => {
    const {accesstoken} = this.props;
    const url= `${process.env.REACT_APP_API_URL}/fscp/checklist_history/?checklist_id=${checklistId}`;

    const signal = this.abortController.signal;
    fetch(url,{
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization' : `Bearer google-oauth2 ${accesstoken}`
      }
    })
    .then(response => response.json())
    .then((jsonStr)=>{
         console.log("jsonStr", jsonStr);
         this.setState({histories: jsonStr});
       })
    .catch(error=> console.log(error))
  };

  componentDidMount() {
    const {checklistId} = this.props;
    this.fetchHistoryHandler(checklistId);
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    return (
        <div className="fscp-history">
                
                <ReactTable  
                    columns={historyColumns}
                    data={this.state.histories}
                    defaultPageSize={maxPaginationRows}
                    // filterable={this.state.histories.length > 1 ? true : false}
                    showPageSizeOptions={false}
                    showPagination={this.state.histories.length > maxPaginationRows ? true : false}
                    className="-highlight"
                />

        </div>
    )
  }
}

export default withCookies(withRouter(FscpHistory));