/* global gapi */
import React, {Component} from 'react'
import ReactTable from 'react-table'
//import "react-table/react-table.css";
import './sea-react-table.css'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import {withFixedColumnsScrollEvent} from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import {withRouter} from 'react-router-dom'
import {withCookies} from 'react-cookie'
import ExportFscpDetail from './ExportFscpDetail'
import moment from 'moment'
import {Link} from 'react-router-dom'
import Modal from 'react-modal'
import Logo from '../images/sprite/icon_entrylist_attachment.png'
import SuccessLogo from '../images/sprite/Success2.png'
import FailedLogo from '../images/sprite/failed.png'
//import { Alert } from 'react-alert'
//import { Progress } from 'react-sweet-progress';
//import "react-sweet-progress/lib/style.css";
import axios from 'axios'
import ReactLoading from 'react-loading'

var countReviewed = 0
const AttachmentStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '50%',
    height: '40%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 1000,
  },
}

const alertCreateStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '22%',
    height: '25%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 1000,
  },
}

const submitmessage = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '40%',
    height: '40%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 1000,
  },
}

const SaveStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '22%',
    height: '25%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255,255,255,0.5)',
    opacity: 0.8,
    outline: 'none',
    border: '1px solid #ffffff',
  },
  overlay: {
    zIndex: 1000,
  },
}

const ReviewingAllStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255,255,255,1.0)',
    opacity: 1.0,
    outline: '2px solid black',
    border: '1px solid #ffffff',
  },
  overlay: {
    zIndex: 1000,
  },
}

//const ReactTableFixedColumns = withFixedColumns(ReactTable);
const ReactTableFixedColumns = withFixedColumnsScrollEvent(ReactTable)

class FscpDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accesstoken: '',
      username: '',
      cookies: this.props.cookies.get('Logins'),
      checklistId: 0,
      periodName: null,
      entityId: null,
      periodId: null,
      checklists: [],
      templateTypes: [],
      expandedTemplates: {0: true, 1: true, 2: true, 3: true},
      UserID: '',
      accesstype: '',
      cloneState: [],
      render: false,
      showAttachment: false,
      selectedChecklistLineId: '',
      AttachmentList: [],
      AttachmentList2: [],
      checkliststatus: '',
      lockedbystate: false,
      selectedChecklistIndex: null,
      clid: null,
      failed: 'Failed to Save',
      count: 0,
      percent: 0,
      refreshupdate: false,
      reviewedRender: true,
    }
    this.fetchDetailDataHandler = this.fetchDetailDataHandler.bind(this)
    this.SaveLines = this.SaveLines.bind(this, true)
    this.checkBoxRef = React.createRef()
  }

  abortController = new AbortController()

  setExpandedRows = (newExpanded, index, e) => {
    this.setState({expandedTemplates: newExpanded})
  }

  fetchDetailDataHandler = (checklistId) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_detail/?checklist_id=${checklistId}`
      const signal = this.abortController.signal
      const {accesstoken} = this.state
      fetch(url, {
        signal: signal,
        method: 'GET',
        headers: {
          Authorization: `Bearer google-oauth2 ${accesstoken}`,
        },
      })
        .then((response) => response.json())
        .then((jsonStr) => {
          const templateTypes = [
            ...new Set(jsonStr.map((item) => item.template_type)),
          ].map((item) => {
            return {template_type: item}
          })
          this.setState({
            checklists: jsonStr,
            AttachmentList2: jsonStr,
            templateTypes: templateTypes,
            checklistnumber: jsonStr[0].checklist_number,
            entityId: jsonStr[0].entity,
            periodName: jsonStr[0].period,
            checkliststatus: jsonStr[0].checklist_status,
            periodId: jsonStr[0].period_id,
          })
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getuserid = () => {
    const {accesstoken} = this.state
    const url = `${process.env.REACT_APP_API_URL}/fscp/current_user/`
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer google-oauth2 ${accesstoken}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({UserID: json})
      })
      .catch((error) => console.log(error))
  }

  getaccesstype = () => {
    const {accesstoken, entityId} = this.state
    const url = `${process.env.REACT_APP_API_URL}/fscp/my_entity_access/?entity_id=${entityId}`
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer google-oauth2 ${accesstoken}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({accesstype: json[0].access_type})
      })
      .catch((error) => console.log(error))
  }

  componentDidMount() {
    const {
      params: {checklistId},
    } = this.props.match
    this.setState({checklistId: checklistId})

    if (this.state.cookies !== 'Test' && this.state.cookies) {
      window.gapi.load('auth2', () => {
        window.gapi.auth2
          .init({
            client_id: `${process.env.REACT_APP_CLIENT_ID}`,
            cookie_policy: 'none',
          })
          .then(async () => {
            gapi.auth2.getAuthInstance().currentUser.listen((usr) =>
              this.setState({
                accesstoken: gapi.auth2
                  .getAuthInstance()
                  .currentUser.get()
                  .getAuthResponse(true).access_token,
                username: gapi.auth2
                  .getAuthInstance()
                  .currentUser.get()
                  .getBasicProfile()
                  .getEmail(),
              })
            )
            this.setState({
              accesstoken: gapi.auth2
                .getAuthInstance()
                .currentUser.get()
                .getAuthResponse(true).access_token,
              username: gapi.auth2
                .getAuthInstance()
                .currentUser.get()
                .getBasicProfile()
                .getEmail(),
            })
            await this.fetchDetailDataHandler(checklistId)
            this.getaccesstype()
            this.getuserid()
          })
      })
    } else {
      gapi.auth2.getAuthInstance().disconnect()
      window.location.href = `${process.env.REACT_APP_REDIRECT_URI}`
    }
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  shouldComponentUpdate(nextProps, nextState) {
    let Total = JSON.parse(JSON.stringify(this.state.checklists))
    if (
      nextState.count === Total.length ||
      (this.state.isSave && nextState.count === 1) ||
      nextState.count === 0 ||
      this.state.refreshupdate
    ) {
      return true
    } else {
      return false
    }
  }

  renderEditable(cellInfo, type) {
    let clone = this.state.checklists
    let clidIndex = clone
      .map((item) => {
        return item.checklist_line_id
      })
      .indexOf(cellInfo.original.checklist_line_id)
    let colVal = this.state.checklists[cellInfo.index][cellInfo.column.id]
    colVal = colVal
      ? colVal
          .replace(/<div>/g, '\n')
          .replace(/<\/div>/g, '')
          .replace(/<br>/g, '\n')
      : colVal
    return (
      <div
        id="content-editable"
        style={
          this.disableTextbox(cellInfo)
            ? {backgroundColor: 'white', minHeight: '15px', border: '1px solid #ebebeb'}
            : null
        }
        contentEditable={this.disableTextbox(cellInfo)}
        suppressContentEditableWarning
        onBlurCapture={(e) => {
          let clone = this.state.checklists
          let columnname = cellInfo.column.id
          let clidIndex = clone
            .map((item) => {
              return item.checklist_line_id
            })
            .indexOf(cellInfo.original.checklist_line_id)
          clone[clidIndex][columnname] = e.target.innerHTML
        }}
        onInput={(e) => {
          let clone = this.state.checklists
          let columnname = cellInfo.column.id
          let clidIndex = clone
            .map((item) => {
              return item.checklist_line_id
            })
            .indexOf(cellInfo.original.checklist_line_id)
          clone[clidIndex].change = true
        }}
        title={colVal}
        dangerouslySetInnerHTML={{
          __html: this.state.checklists[clidIndex][cellInfo.column.id],
        }}
      />
    )
  }

  disableGeneral(props) {
    if (!this.state.UserID) {
      return
    }

    if (
      (this.state.accesstype.toUpperCase() === 'PREPARER' &&
        (props.original.status === 'PREPARED' || props.original.status === 'REVIEWED') &&
        props.original.prepared_by !== this.state.UserID[0]['id']) ||
      (this.state.accesstype.toUpperCase() === 'REVIEWER' &&
        (((props.original.status === null || props.original.status === '') &&
          (props.original.prepared_by === '' || props.original.prepared_by === null)) ||
          this.state.checkliststatus.toUpperCase() !== 'CREATED' ||
          (props.original.status === 'REVIEWED' &&
            props.original.reviewed_by !== this.state.UserID[0]['id'] &&
            props.original.reviewed_by !== '' &&
            props.original.reviewed_by !== null))) ||
      this.state.accesstype.toUpperCase() === 'HQ' ||
      this.state.accesstype.toUpperCase() === 'INQUIRY' ||
      this.state.checkliststatus === 'APPROVED'
    ) {
      return true
    } else {
      return false
    }
  }

  disableTextbox(props) {
    if (!this.state.UserID) {
      return
    }

    if (
      (this.state.accesstype.toUpperCase() === 'PREPARER' &&
        (props.original.status === 'PREPARED' || props.original.status === 'REVIEWED') &&
        props.original.prepared_by !== this.state.UserID[0]['id']) ||
      (this.state.accesstype.toUpperCase() === 'REVIEWER' &&
        (((props.original.status === null || props.original.status === '') &&
          (props.original.prepared_by === '' || props.original.prepared_by === null)) ||
          this.state.checkliststatus.toUpperCase() !== 'CREATED' ||
          (props.original.status === 'REVIEWED' &&
            props.original.reviewed_by !== this.state.UserID[0]['id'] &&
            props.original.reviewed_by !== '' &&
            props.original.reviewed_by !== null))) ||
      this.state.accesstype.toUpperCase() === 'HQ' ||
      this.state.accesstype.toUpperCase() === 'INQUIRY' ||
      this.state.checkliststatus === 'APPROVED'
    ) {
      return false
    } else {
      return true
    }
  }

  disableReviewedCheckbox(props) {
    if (!this.state.UserID) {
      return
    }

    if (
      this.state.accesstype.toUpperCase() === 'PREPARER' ||
      (this.state.accesstype.toUpperCase() === 'REVIEWER' &&
        (((props.original.status === null || props.original.status === '') &&
          (props.original.prepared_by === '' || props.original.prepared_by === null)) ||
          this.state.checkliststatus.toUpperCase() !== 'CREATED' ||
          (props.original.status === 'REVIEWED' &&
            props.original.reviewed_by !== this.state.UserID[0]['id'] &&
            props.original.reviewed_by !== '' &&
            props.original.reviewed_by !== null))) ||
      this.state.accesstype.toUpperCase() === 'HQ' ||
      this.state.accesstype.toUpperCase() === 'INQUIRY'
    ) {
      return true
    } else {
      return false
    }
  }

  disableEditButton(props) {
    if (!this.state.UserID) {
      return
    }

    if (
      (this.state.accesstype.toUpperCase() === 'PREPARER' &&
        (props.original.locked_by ||
          props.original.status === 'PREPARED' ||
          props.original.status === 'REVIEWED')) ||
      (this.state.accesstype.toUpperCase() === 'REVIEWER' &&
        (props.original.locked_by ||
          props.original.status === null ||
          props.original.status === '' ||
          this.state.checkliststatus.toUpperCase() !== 'CREATED' ||
          (props.original.status === 'REVIEWED' &&
            props.original.reviewed_by !== this.state.UserID[0]['id']))) ||
      this.state.accesstype.toUpperCase() === 'HQ' ||
      this.state.accesstype.toUpperCase() === 'INQUIRY'
    ) {
      return true
    } else {
      return false
    }
  }

  disableSaveButton(props) {
    if (!this.state.UserID) {
      return
    }

    if (
      (this.state.accesstype.toUpperCase() === 'PREPARER' &&
        ((props.original.locked_by &&
          props.original.locked_by !== this.state.UserID[0]['id']) ||
          props.original.locked_by !== this.state.UserID[0]['id'])) ||
      (this.state.accesstype.toUpperCase() === 'REVIEWER' &&
        ((props.original.locked_by &&
          props.original.locked_by !== this.state.UserID[0]['id']) ||
          props.original.locked_by !== this.state.UserID[0]['id']) &&
        this.state.checkliststatus.toUpperCase !== 'CREATED') ||
      this.state.accesstype.toUpperCase() === 'HQ' ||
      this.state.accesstype.toUpperCase() === 'INQUIRY'
    ) {
      return true
    } else {
      return false
    }
  }

  defaultChecked(props) {
    if (props === 'PREPARED' || props === 'REVIEWED') {
      return true
    } else {
      return false
    }
  }

  ChecklistReviewed() {
    let Review = JSON.parse(JSON.stringify(this.state.checklists)).filter((item) => {
      return item.status === 'REVIEWED'
    })
    let Total = JSON.parse(JSON.stringify(this.state.checklists))

    if (
      Review.length / Total.length === 1 &&
      this.state.reviewedRender &&
      this.state.checkliststatus.toUpperCase() !== 'APPROVED' &&
      this.state.accesstype.toUpperCase() === 'REVIEWER'
    ) {
      this.setState({SubmittedMessage: true, reviewedRender: false})
    }

    return `${Review.length}/${Total.length}`
  }

  GetpostLockedby = (props) => {
    const {accesstoken} = this.state

    const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_detail/?checklist_id=${this.state.checklistId}&checklist_line_id=${props.original.checklist_line_id}`
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer google-oauth2 ${accesstoken}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        let clidIndex = this.state.checklists
          .map((item) => {
            return item.checklist_line_id
          })
          .indexOf(props.original.checklist_line_id)

        json[0].locked_by
          ? alert('Checklist Line already edited by other user, please try again later')
          : props.original.last_update_date !== json[0].last_update_date
          ? props.original.status !== json[0].status
            ? //alert("Status have change for this checklist, please try edit again")
              this.setState((prevState) => {
                const newchecklists = [...prevState.checklists]
                //console.log("newch ",newchecklists[clidIndex])
                newchecklists[clidIndex] = json[0]
                return {checklists: newchecklists}
              })(
                alert('Status have change for this checklist, please try edit again'),
                this.setState({render: true})
              )
            : this.setState((prevState) => {
                const newchecklists = [...prevState.checklists]
                console.log('newch ', newchecklists[clidIndex])
                newchecklists[clidIndex] = json[0]
                return {checklists: newchecklists}
              })(this.postLockedby(props), this.setState({render: true}))
          : this.postLockedby(props)
      })
      .catch((error) => console.log(error))
  }

  postLockedby = (props) => {
    const {accesstoken, UserID} = this.state
    const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_line/${props.original.checklist_line_id}/`
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({
        locked_by: UserID[0]['id'],
        template_id: props.original.template_id,
        line_id: props.original.line_id,
        task_group: props.original.task_group,
        item: props.original.item,
        frequency: props.original.frequency,
        checklist_id: props.original.checklist_id,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer google-oauth2 ${accesstoken}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_detail/?checklist_id=${this.state.checklistId}`
        const signal = this.abortController.signal
        const {accesstoken} = this.state
        fetch(url, {
          signal: signal,
          method: 'GET',
          headers: {
            Authorization: `Bearer google-oauth2 ${accesstoken}`,
          },
        })
          .then((response) => response.json())
          .then((jsonStr) => {
            let saveState = this.state.checklists
            let clidIndex = saveState
              .map((item) => {
                return item.checklist_line_id
              })
              .indexOf(props.original.checklist_line_id)
            this.setState((prevState) => {
              const newchecklists = [...prevState.checklists]
              newchecklists[clidIndex].locked_by = jsonStr[clidIndex].locked_by
              newchecklists[clidIndex].locked_by_user = jsonStr[clidIndex].locked_by_user
              return {checklists: newchecklists}
            })
          })
      })
      .catch((error) => console.log(error))
  }

  SaveLines = (e) => {
    const {accesstoken} = this.state
    let Total = JSON.parse(JSON.stringify(this.state.checklists))
    countReviewed = JSON.parse(JSON.stringify(this.state.checklists)).filter((item) => {
      return item.status === 'REVIEWED'
    })
    this.setState({percent: 0})
    this.setState({count: 0})
    this.setState({refreshupdate: false})

    this.state.checklists.map((item, index) => {
      this.setState((prevState) => {
        const newchecklists = [...prevState.checklists]
        newchecklists[index].savestatus = ''
        return {checklists: newchecklists}
      })
      const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_detail/?checklist_id=${this.state.checklistId}&checklist_line_id=${item.checklist_line_id}`

      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer google-oauth2 ${accesstoken}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          let clone = this.state.checklists

          // will save item that has changes
          item.change
            ? item.last_update_date === json[0].last_update_date
              ? this.saveData(item)
              : this.setState((prevState) => {
                  const newchecklists = [...prevState.checklists]
                  newchecklists[index].savestatus = 'Failed to Save'
                  return {
                    checklists: newchecklists,
                    count: this.state.count + 1,
                    percent: ((this.state.count + 1) / Total.length) * 100,
                  }
                })
            : this.setState({
                count: this.state.count + 1,
                percent: ((this.state.count + 1) / Total.length) * 100,
              })
        })
    })

    //check if 100% prepared, send email to reviewer
    let Prepared = JSON.parse(JSON.stringify(this.state.checklists)).filter((item) => {
      return item.status === 'PREPARED'
    })
    if ((Prepared.length / Total.length) * 100 === 100) {
      this.sendEmailForReviewer()
    }
  }

  saveData = (props) => {
    const {accesstoken, UserID} = this.state
    let saveState = this.state.checklists
    let clidIndex = saveState
      .map((item) => {
        return item.checklist_line_id
      })
      .indexOf(props.checklist_line_id)
    if (saveState[clidIndex].status === 'PREPARED') {
      saveState[clidIndex].prepared_by = UserID[0]['id']
      saveState[clidIndex].prepared_date = moment().format('YYYY-MM-DD')
      saveState[clidIndex].reviewed_by = null
      saveState[clidIndex].reviewed_date = null
    } else if (saveState[clidIndex].status === 'REVIEWED') {
      saveState[clidIndex].reviewed_by = UserID[0]['id']
      saveState[clidIndex].reviewed_date = moment().format('YYYY-MM-DD')
    } else {
      saveState[clidIndex].prepared_by = null
      saveState[clidIndex].prepared_date = null
      saveState[clidIndex].reviewed_by = null
      saveState[clidIndex].reviewed_date = null
    }

    if (
      saveState[clidIndex].conclusion === null ||
      saveState[clidIndex].conclusion === ''
    ) {
      saveState[clidIndex].conclusion = 'No Exceptions Noted'
    }

    const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_line/${props.checklist_line_id}/`
    let formData = new FormData()
    if (saveState[clidIndex].attachment) {
      formData.append('file', saveState[clidIndex].attachment)
      formData.append('filename', saveState[clidIndex].attachment.name)
      formData.append('description', saveState[clidIndex].attachment.name)
      formData.append('checklist_line_id', props.checklist_line_id)
      formData.append('created_by', UserID[0]['id'])
      fetch(`${process.env.REACT_APP_API_URL}/fscp/checklist_line_attachment/`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer google-oauth2 ${accesstoken}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_detail/?checklist_id=${this.state.checklistId}`
          const signal = this.abortController.signal
          const {accesstoken} = this.state
          fetch(url, {
            signal: signal,
            method: 'GET',
            headers: {
              Authorization: `Bearer google-oauth2 ${accesstoken}`,
            },
          })
            .then((response) => response.json())
            .then((jsonStr) => {
              saveState[clidIndex].attachment = null
              this.setState({render: true})
            })
        })
        .catch((error) => {
          alert(`Failed to upload file for line id : ${props.line_id}, please try again`)
          saveState[clidIndex].attachment = null
        })
    }
    if (
      this.state.accesstype.toUpperCase() === 'REVIEWER' &&
      saveState[clidIndex].status === 'PREPARED'
    ) {
      fetch(url, {
        method: 'PUT',
        body: JSON.stringify({
          locked_by: null,
          template_id: props.template_id,
          line_id: props.line_id,
          task_group: props.task_group,
          item: props.item,
          frequency: props.frequency,
          checklist_id: props.checklist_id,
          working_paper_reference: saveState[clidIndex].working_paper_reference,
          conclusion: saveState[clidIndex].conclusion,
          matters_to_highlight: saveState[clidIndex].matters_to_highlight,
          action_plan: saveState[clidIndex].action_plan,
          status: saveState[clidIndex].status,
          control: saveState[clidIndex].control,
          key_report: saveState[clidIndex].key_report,
          reviewed_by: saveState[clidIndex].reviewed_by,
          reviewed_date: saveState[clidIndex].reviewed_date,
          last_updated_by: UserID[0]['id'],
        }),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer google-oauth2 ${accesstoken}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_detail/?checklist_id=${this.state.checklistId}`
          //const signal = this.abortController.signal;
          const {accesstoken} = this.state
          fetch(url, {
            //signal: signal,
            method: 'GET',
            headers: {
              Authorization: `Bearer google-oauth2 ${accesstoken}`,
            },
          })
            .then((response) => response.json())
            .then((jsonStr) => {
              let saveState = this.state.checklists
              let clidIndex = saveState
                .map((item) => {
                  return item.checklist_line_id
                })
                .indexOf(props.checklist_line_id)
              this.setState((prevState) => {
                const newchecklists = [...prevState.checklists]
                newchecklists[clidIndex].locked_by = jsonStr[clidIndex].locked_by
                newchecklists[clidIndex].locked_by_user =
                  jsonStr[clidIndex].locked_by_user
                newchecklists[clidIndex].reviewed_by = jsonStr[clidIndex].reviewed_by
                newchecklists[clidIndex].reviewed_by_user =
                  jsonStr[clidIndex].reviewed_by_user
                newchecklists[clidIndex].prepared_by = jsonStr[clidIndex].prepared_by
                newchecklists[clidIndex].prepared_by_user =
                  jsonStr[clidIndex].prepared_by_user
                newchecklists[clidIndex].prepared_date = jsonStr[clidIndex].prepared_date
                newchecklists[clidIndex].reviewed_date = jsonStr[clidIndex].reviewed_date
                newchecklists[clidIndex].status = jsonStr[clidIndex].status
                newchecklists[clidIndex].attachments = jsonStr[clidIndex].attachments
                newchecklists[clidIndex].last_update_date =
                  jsonStr[clidIndex].last_update_date
                newchecklists[clidIndex].change = false
                newchecklists[clidIndex].savestatus = 'Success to Save'
                newchecklists[clidIndex].statusprepared = ''
                return {checklists: newchecklists}
              })
              this.setState({render: true})
              let Total = JSON.parse(JSON.stringify(this.state.checklists))
              this.setState({
                count: this.state.count + 1,
                percent: ((this.state.count + 1) / Total.length) * 100,
              })
            })
        })
        .catch((error) => console.log('error'))
    } else {
      axios
        .request({
          method: 'PUT',
          url: url,
          data: JSON.stringify({
            locked_by: null,
            template_id: props.template_id,
            line_id: props.line_id,
            task_group: props.task_group,
            item: props.item,
            frequency: props.frequency,
            checklist_id: props.checklist_id,
            working_paper_reference: saveState[clidIndex].working_paper_reference,
            conclusion: saveState[clidIndex].conclusion,
            matters_to_highlight: saveState[clidIndex].matters_to_highlight,
            action_plan: saveState[clidIndex].action_plan,
            status:
              this.state.accesstype.toUpperCase() === 'PREPARER' &&
              saveState[clidIndex].status === 'REVIEWED'
                ? 'PREPARED'
                : saveState[clidIndex].status,
            control: saveState[clidIndex].control,
            key_report: saveState[clidIndex].key_report,
            prepared_by: saveState[clidIndex].prepared_by,
            prepared_date: saveState[clidIndex].prepared_date,
            reviewed_by:
              this.state.accesstype.toUpperCase() === 'PREPARER' &&
              saveState[clidIndex].status === 'REVIEWED'
                ? null
                : saveState[clidIndex].reviewed_by,
            reviewed_date:
              this.state.accesstype.toUpperCase() === 'PREPARER' &&
              saveState[clidIndex].status === 'REVIEWED'
                ? null
                : saveState[clidIndex].reviewed_date,
            last_updated_by: UserID[0]['id'],
          }),
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer google-oauth2 ${accesstoken}`,
          },
          onUploadProgress: (p) => {
            this.setState({
              fileprogress: p.loaded / p.total,
            })
          },
        })
        .then((json) => {
          const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_detail/?checklist_id=${this.state.checklistId}`
          const signal = this.abortController.signal
          const {accesstoken} = this.state
          fetch(url, {
            signal: signal,
            method: 'GET',
            headers: {
              Authorization: `Bearer google-oauth2 ${accesstoken}`,
            },
          })
            .then((response) => response.json())
            .then((jsonStr) => {
              let saveState = this.state.checklists
              let clidIndex = saveState
                .map((item) => {
                  return item.checklist_line_id
                })
                .indexOf(props.checklist_line_id)
              this.setState((prevState) => {
                const newchecklists = [...prevState.checklists]
                newchecklists[clidIndex].locked_by = jsonStr[clidIndex].locked_by
                newchecklists[clidIndex].locked_by = jsonStr[clidIndex].locked_by
                newchecklists[clidIndex].locked_by_user =
                  jsonStr[clidIndex].locked_by_user
                newchecklists[clidIndex].prepared_by = jsonStr[clidIndex].prepared_by
                newchecklists[clidIndex].prepared_by_user =
                  jsonStr[clidIndex].prepared_by_user
                newchecklists[clidIndex].prepared_date = jsonStr[clidIndex].prepared_date
                newchecklists[clidIndex].reviewed_by = jsonStr[clidIndex].reviewed_by
                newchecklists[clidIndex].reviewed_by_user =
                  jsonStr[clidIndex].reviewed_by_user
                newchecklists[clidIndex].reviewed_date = jsonStr[clidIndex].reviewed_date
                newchecklists[clidIndex].status = jsonStr[clidIndex].status
                newchecklists[clidIndex].attachments = jsonStr[clidIndex].attachments
                newchecklists[clidIndex].last_update_date =
                  jsonStr[clidIndex].last_update_date
                newchecklists[clidIndex].change = false
                newchecklists[clidIndex].savestatus = 'Success to Save'
                newchecklists[clidIndex].statusprepared = ''
                return {checklists: newchecklists}
              })

              this.setState({render: true})
              let Total = JSON.parse(JSON.stringify(this.state.checklists))
              this.setState({
                count: this.state.count + 1,
                percent: ((this.state.count + 1) / Total.length) * 100,
              })
            })
        })
        .catch((error) => console.log('error', error))
    }
  }

  sendEmailForReviewer() {
    const {accesstoken} = this.state
    const url = `${process.env.REACT_APP_API_URL}/send_email/`
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify({
        checklist_id: this.state.checklistId,
        checklist_number: this.state.checklistnumber,
        entity: this.state.entityId,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer google-oauth2 ${accesstoken}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {})
      .catch((error) => console.log(error))
  }

  submitDisabled() {
    let Review = JSON.parse(JSON.stringify(this.state.checklists)).filter((item) => {
      return item.status === 'REVIEWED'
    })
    let Total = JSON.parse(JSON.stringify(this.state.checklists))
    if (
      Total.length === Review.length &&
      this.state.accesstype.toUpperCase() === 'REVIEWER' &&
      this.state.checkliststatus.toUpperCase() === 'CREATED'
    ) {
      return false
    } else {
      return true
    }
  }

  DisabledButton() {
    if (
      this.state.checkliststatus.toUpperCase() === 'CREATED' &&
      this.state.accesstype.toUpperCase() !== 'INQUIRY'
    ) {
      return false
    } else {
      return true
    }
  }

  submitData = () => {
    let Validation = JSON.parse(JSON.stringify(this.state.checklists)).filter((item) => {
      return item.locked_by !== null && item.locked_by !== ''
    })
    const approvedTarget = this.state.checklists.length
    const ReviewedTarget = this.state.checklists.length
    var preparedCount = 0
    var reviewedCount = 0
    this.state.checklists.filter((item) => {
      if (item.status === 'PREPARED') {
        preparedCount++
      } else if (item.status === 'REVIEWED') {
        preparedCount++
        reviewedCount++
      }
    })

    if (
      preparedCount === this.state.checklists.length &&
      reviewedCount === this.state.checklists.length
    ) {
      if (Validation.length) {
        alert('Please save all lines before submit')
      } else {
        const {UserID, periodId, entityId, accesstoken, checklistId} = this.state
        const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_header/${checklistId}/`
        const signal = this.abortController.signal
        fetch(url, {
          signal: signal,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer google-oauth2 ${accesstoken}`,
          },
          body: JSON.stringify({
            period_id: periodId,
            entity_id: entityId,
            status: 'APPROVED',
            last_updated_by: UserID[0]['id'],
          }),
        })
          .then((response) => response.json())
          .then((jsonStr) => {
            this.setState({isSubmitted: true})
          })
          .catch((error) => console.log(error))
      }
    }
  }

  download(attachment_id) {
    const url = `${process.env.REACT_APP_API_URL}/download/?attachment_id=${attachment_id}`
    const signal = this.abortController.signal
    fetch(url, {
      signal: signal,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer google-oauth2 ${this.state.accesstoken}`,
      },
    }).then(async (resp) => {
      if (resp.status !== 200) {
        alert('Error while download attachment')
        return
      }

      const fileName = resp.headers
        .get('content-disposition')
        .split('filename=')[1]
        .replaceAll('"', '')

      const blob = await resp.blob()
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }

  deleteattachment(attachment_id, filename) {
    const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_line_attachment/${attachment_id}/`
    const signal = this.abortController.signal
    fetch(url, {
      signal: signal,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer google-oauth2 ${this.state.accesstoken}`,
      },
    }).then((resp) => {
      this.setState({render: true})
      alert(
        `Attachment file ${filename} has been deleted. \n Click [Ok] to close this window.`
      )
      //this.closeAttachment()
    })
  }

  deleteattachmentprompt(item) {
    if (
      window.confirm(
        `Are you sure you want to delete attachment ${item.filename}? \n Click [Ok] to confirm deletion or [Cancel] to abort.`
      )
    ) {
      this.deleteattachment(item.attachment_id, item.filename)
    }
  }

  attachmentlist(props) {
    return (
      <Link>
        {props.original.attachments.map((item, i) => {
          return (
            <div>
              <li onClick={(e) => this.download(item.attachment_id)}>{item.filename}</li>
            </div>
          )
        })}
      </Link>
    )
  }

  openAttachment = (props) => {
    const {checklist_line_id, checklist_id} = props.original
    const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_detail/?checklist_id=${checklist_id}&checklist_line_id=${props.original.checklist_line_id}`
    const signal = this.abortController.signal
    const {accesstoken} = this.state
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        Authorization: `Bearer google-oauth2 ${accesstoken}`,
      },
    })
      .then((response) => response.json())
      .then((jsonStr) => {
        this.setState({AttachmentList: jsonStr[0]})
        this.setState({clid: checklist_line_id})
        this.setState({showAttachment: true})
        this.setState((prevState) => {
          const newchecklists = [...prevState.checklists]
          newchecklists[props.index].showAttachment =
            !newchecklists[props.index].showAttachment
          return {checklists: newchecklists}
        })
      })
    this.setState({selectedChecklistIndex: props.index})
  }

  closeAttachment = () => {
    //this.setState({showAttachment: false, selectedChecklistLineId : null});
    const {selectedChecklistIndex} = this.state
    this.setState((prevState) => {
      const newchecklists = [...prevState.checklists]
      newchecklists[selectedChecklistIndex].showAttachment =
        !newchecklists[selectedChecklistIndex].showAttachment
      return {checklists: newchecklists}
    })
    this.setState({selectedChecklistIndex: null})
  }

  closeCreateAlert = () => {
    this.setState({isSubmitted: false})
    this.props.history.push({
      pathname: `/fscp`,
    })
  }

  closeExportAlert = () => {
    this.setState({
      isExport: false,
      isRefresh: false,
      isSave: false,
      refreshupdate: false,
    })
  }

  closeSubmitAlert = () => {
    this.setState({SubmittedMessage: false, AllReviewed: false})
  }

  refreshattachment() {
    const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_detail/?checklist_id=${this.state.checklistId}`
    //const signal = this.abortController.signal;
    const {accesstoken} = this.state
    fetch(url, {
      //signal: signal,
      method: 'GET',
      headers: {
        Authorization: `Bearer google-oauth2 ${accesstoken}`,
      },
    })
      .then((response) => response.json())
      .then((jsonStr) => {
        let clidIndex = jsonStr
          .map((item) => {
            return item.checklist_line_id
          })
          .indexOf(this.state.clid)
        this.setState({
          AttachmentList2: jsonStr,
          AttachmentList: jsonStr[clidIndex],
          render: false,
        })
      })
  }

  filterCaseInsensitive = ({id, value}, row) => {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }

  savebuttonenable() {
    return (
      <button
        className={'fscpdetail-btn btn-focus'}
        onClick={(e) => {
          this.setState({isSave: true})
          this.SaveLines(true)
        }}
      >
        Save
      </button>
    )
  }

  disableClose() {
    let Total = JSON.parse(JSON.stringify(this.state.checklists))
    if (this.state.count === Total.length) {
      return false
    } else {
      return true
    }
  }

  reviewAll = async () => {
    this.state.checklists
      .filter((item) => {
        return item.status === 'PREPARED'
      })
      .map((filter, index) => {
        let clidIndex = this.state.checklists
          .map((item) => {
            return item.checklist_line_id
          })
          .indexOf(filter.checklist_line_id)

        this.setState((prevState) => {
          const newchecklists = [...prevState.checklists]
          newchecklists[clidIndex].status = 'REVIEWED'
          newchecklists[clidIndex].change = true
          return {checklists: newchecklists}
        })
      })

    this.setState({isReviewingAll: true, SubmittedMessage: false})
    //Call API to save those record that changed
    this.SaveLines(true)
  }

  checkAllReviewed = async () => {
    let preparedCount = 0
    let reviewedCount = 0
    this.state.checklists.filter((item) => {
      if (item.status === 'PREPARED') {
        preparedCount++
      } else if (item.status === 'REVIEWED') {
        preparedCount++
        reviewedCount++
      }
    })

    if (
      preparedCount === this.state.checklists.length &&
      reviewedCount === this.state.checklists.length
    ) {
      this.setState({AllReviewed: true})
    }
  }
  reviewAndApprove = async () => {
    try {
      this.setState({reviewedRender: false})

      //review all checklists
      await this.reviewAll()
      //check if all are reviewed
      await this.checkAllReviewed()
    } catch (error) {
      // Handle errors if any
      console.error('Error:', error)
    }
  }

  render() {
    const {checklistId, periodName, entityId, checklistnumber} = this.state
    const {isMenuExpanded, menuToggle} = this.props

    const templateColumns = [
      {
        Header: '',
        id: 'template_type',
        accessor: (props) =>
          `${props.template_type.charAt(0).toUpperCase()}${props.template_type
            .slice(1)
            .toLowerCase()} Specific`,
        width: 250,
        minwidth: 250,
        maxwidth: 250,
        style: {
          textAlign: 'Left',
        },
      },
    ]

    const customFilter = ({filter, onChange}) => {
      return (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{width: '100%'}}
        >
          <option value="all">Show All</option>
          <option value="Prepared">Prepared</option>
          <option value="Reviewed">Reviewed</option>
          <option value="NotPrepared">Not Prepared</option>
        </select>
      )
    }

    const customFilterFrequency = ({filter, onChange}) => {
      return (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{width: '100%'}}
        >
          <option value="all">Show All</option>
          <option value="Monthly">Monthly</option>
          <option value="Quarterly">Quarterly</option>
          <option value="Annually">Annually</option>
        </select>
      )
    }

    const checklistColumns = [
      {
        Header: '',
        fixed: 'left',
        columns: [
          {
            Header: '',
            id: 'row',
            width: 30,
            filterable: false,
            Cell: (row) => {
              return <div>{row.index + 1}</div>
            },
          },
          {
            Header: 'Task Group',
            accessor: 'task_group',
            width: 120,
            minwidth: 250,
            maxwidth: 250,
            style: {
              textAlign: 'Left',
              whiteSpace: 'unset',
            },
            Cell: (row) => (
              <div>
                <span title={row.value}>{row.value}</span>
              </div>
            ),
          },
          {
            Header: 'Item',
            accessor: 'item',
            width: 120,
            minwidth: 200,
            maxwidth: 200,
            style: {
              textAlign: 'left',
              whiteSpace: 'unset',
            },
            Cell: (row) => (
              <div>
                <span title={row.value}>{row.value}</span>
              </div>
            ),
          },
        ],
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        width: 100,
        minwidth: 100,
        maxwidth: 100,
        style: {
          textAlign: 'left',
        },
        Cell: (row) => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === 'all') {
            return row
          } else {
            if (row[filter.id] !== null && row[filter.id] !== '') {
              return row[filter.id].match(filter.value)
            }
          }
        },
        Filter: ({filter, onChange}) => customFilterFrequency({filter, onChange}),
      },

      {
        Header: 'Description',
        accessor: 'description',
        width: 300,
        minwidth: 300,
        maxwidth: 300,
        style: {
          textAlign: 'left',
          whiteSpace: 'unset',
        },
        Cell: (row) => (
          <div
            dangerouslySetInnerHTML={{
              __html: row.value.replace(/\n/g, '<br>'),
            }}
          />
        ),
      },
      {
        Header: 'Documentation Requirement',
        accessor: 'documentation_requirement',
        width: 200,
        minwidth: 200,
        maxwidth: 200,
        style: {
          textAlign: 'left',
          whiteSpace: 'unset',
        },
        Cell: (row) => (
          <div
            dangerouslySetInnerHTML={{
              __html: row.value.replace(/\n/g, '<br>'),
            }}
            title={row.value}
          />
        ),
      },
      {
        Header: 'Working Paper Reference',
        accessor: 'working_paper_reference',
        width: 150,
        style: {
          textAlign: 'left',
        },
        Cell: (cellinfo, props) =>
          this.renderEditable(cellinfo, cellinfo.original.locked_by),
      },
      {
        Header: 'Conclusion',
        accessor: 'conclusion',
        width: 160,
        style: {
          textAlign: 'left',
        },
        Cell: (props) => {
          let clone = this.state.checklists
          let clidIndex = clone
            .map((item) => {
              return item.checklist_line_id
            })
            .indexOf(props.original.checklist_line_id)
          return (
            <div>
              <select
                style={{width: '150px'}}
                onChange={(e) => {
                  let value = e.target.value
                  let clone = this.state.checklists
                  clone[clidIndex].conclusion = value
                  clone[clidIndex].change = true
                }}
                defaultValue={this.state.checklists[clidIndex].conclusion}
                disabled={this.disableGeneral(props)}
              >
                <option>No Exceptions Noted</option>
                <option>N/A</option>
                <option>Others, Fill up matters to highlight</option>
              </select>
            </div>
          )
        },
      },
      {
        Header: 'Matters To Highlight',
        accessor: 'matters_to_highlight',
        width: 200,
        minwidth: 200,
        maxwidth: 200,
        style: {
          textAlign: 'left',
        },
        Cell: (cellinfo, props) =>
          this.renderEditable(cellinfo, cellinfo.original.locked_by),
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: {
          paddingLeft: 10,
        },
        width: 110,
        Aggregated: (row) => {
          return ''
        },
        Cell: (props) => {
          return (
            <div>
              <div>
                <label>
                  <input
                    key={props.original.checklist_line_id}
                    type="checkbox"
                    name="Prepared"
                    value="PREPARED"
                    id={'prepared'}
                    disabled={this.disableGeneral(props)}
                    defaultChecked={
                      props.original.status === 'PREPARED' ||
                      props.original.status === 'REVIEWED'
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      let clone = this.state.checklists
                      let clidIndex = clone
                        .map((item) => {
                          return item.checklist_line_id
                        })
                        .indexOf(props.original.checklist_line_id)
                      if (e.target.checked)
                        if (props.original.status === 'REVIEWED') {
                          return (
                            (clone[clidIndex].status = 'REVIEWED'),
                            (clone[clidIndex].statusprepared = ''),
                            (clone[clidIndex].change = true)
                          )
                        } else {
                          return (
                            (clone[clidIndex].status = e.target.value),
                            (clone[clidIndex].statusprepared = ''),
                            (clone[clidIndex].change = true)
                          )
                        }
                      else {
                        if (props.original.status === 'REVIEWED') {
                          return (
                            (clone[clidIndex].status = 'REVIEWED'),
                            (clone[clidIndex].statusprepared = 'P'),
                            (clone[clidIndex].change = true)
                          )
                        } else {
                          return (
                            (clone[clidIndex].status = ''),
                            (clone[clidIndex].statusprepared = ''),
                            (clone[clidIndex].change = true)
                          )
                        }
                      }
                    }}
                  />
                  Prepared
                </label>
              </div>
              <div>
                <label>
                  <input
                    key={props.original.checklist_line_id}
                    type="checkbox"
                    name="Reviewed"
                    value="REVIEWED"
                    disabled={this.disableReviewedCheckbox(props)}
                    defaultChecked={props.original.status === 'REVIEWED' ? true : false}
                    onChange={(e) => {
                      let clone = this.state.checklists
                      let clidIndex = clone
                        .map((item) => {
                          return item.checklist_line_id
                        })
                        .indexOf(props.original.checklist_line_id)
                      if (e.target.checked)
                        return (
                          (clone[clidIndex].status = 'REVIEWED'),
                          (clone[clidIndex].change = true)
                        )
                      else {
                        if (clone[clidIndex].statusprepared === 'P') {
                          return (
                            (clone[clidIndex].status = ''),
                            (clone[clidIndex].change = true)
                          )
                        } else {
                          return (
                            (clone[clidIndex].status = 'PREPARED'),
                            (clone[clidIndex].change = true)
                          )
                        }
                      }
                    }}
                  />
                  Reviewed
                </label>
              </div>
            </div>
          )
        },
        filterMethod: (filter, row) => {
          if (filter.value === 'Prepared' || filter.value === 'Reviewed') {
            if (row[filter.id] !== null && row[filter.id] !== '') {
              return row[filter.id].startsWith(filter.value.toUpperCase())
            }
          } else if (filter.value === 'all') {
            return row
          } else if (filter.value === 'NotPrepared') {
            if (row[filter.id] === null || row[filter.id] === '') {
              return row
            }
          }
        },
        Filter: ({filter, onChange}) => customFilter({filter, onChange}),
      },
      {
        Header: 'Controls',
        accessor: 'control',
        width: 100,
        minwidth: 100,
        maxwidth: 100,
        style: {
          textAlign: 'left',
        },
        show: false,
        Cell: (cellinfo, props) =>
          this.renderEditable(cellinfo, cellinfo.original.locked_by),
      },
      {
        Header: 'Key Report',
        accessor: 'key_report',
        width: 100,
        minwidth: 100,
        maxwidth: 100,
        style: {
          textAlign: 'left',
        },
        show: false,
        Cell: (cellinfo, props) =>
          this.renderEditable(cellinfo, cellinfo.original.locked_by),
      },
      {
        Header: 'Attachment',
        accessor: 'Attachment',
        filterable: false,
        style: {
          textAlign: 'left',
        },
        width: 200,
        Aggregated: (row) => {
          return ''
        },
        Cell: (props) => {
          return (
            <div className="fscp-detail-attach-wrapper">
              <input
                type="file"
                name="attachment"
                title="Upload"
                onChange={(e) => {
                  let clone = this.state.checklists
                  let clidIndex = clone
                    .map((item) => {
                      return item.checklist_line_id
                    })
                    .indexOf(props.original.checklist_line_id)
                  clone[clidIndex].attachment = e.target.files[0]
                  clone[clidIndex].change = true
                }}
                disabled={this.disableGeneral(props)}
              />
            </div>
          )
        },
      },
      {
        Header: '',
        filterable: false,
        style: {},
        minWidth: 35,
        Cell: (props) => {
          let clidIndex = this.state.AttachmentList2.map((item) => {
            return item.checklist_line_id
          }).indexOf(props.original.checklist_line_id)
          return this.state.AttachmentList2[clidIndex].attachments.length > 0 ? (
            <div>
              <img
                src={Logo}
                className="attachment-logos"
                onClick={(e) => this.openAttachment(props)}
                alt="attachment"
              />
              {this.state.checklists[props.index].showAttachment && (
                <Modal
                  isOpen={this.state.checklists[props.index].showAttachment}
                  onRequestClose={this.closeAttachment}
                  style={AttachmentStyles}
                  shouldCloseOnOverlayClick={false}
                >
                  <table>
                    <td>
                      <Link>
                        {this.state.AttachmentList.attachments.map((item, i) => {
                          return (
                            <div>
                              <ol>
                                <li
                                  key={item.attachment_id}
                                  onClick={(e) => this.download(item.attachment_id)}
                                >
                                  {item.filename}
                                </li>
                              </ol>
                            </div>
                          )
                        })}
                      </Link>
                    </td>
                    <td>
                      <Link>
                        {this.state.checkliststatus.toUpperCase() === 'CREATED' &&
                        this.state.accesstype.toUpperCase() !== 'INQUIRY'
                          ? this.state.AttachmentList.attachments.map((item, i) => {
                              return (
                                <div>
                                  <ol>
                                    <li
                                      key={item.attachment_id}
                                      onClick={(e) => this.deleteattachmentprompt(item)}
                                    >
                                      Delete
                                    </li>
                                  </ol>
                                </div>
                              )
                            })
                          : null}
                      </Link>
                    </td>
                  </table>
                  <div className="fscp-history-wrapper">
                    <button
                      className="fscp-history-btn btn-neutral"
                      onClick={this.closeAttachment}
                    >
                      Close
                    </button>
                  </div>
                </Modal>
              )}
            </div>
          ) : null
        },
      },
      {
        Header: 'Preparer Date',
        accessor: 'prepared_date',
        width: 100,
        minwidth: 100,
        maxwidth: 100,
        style: {
          textAlign: 'center',
        },
        Cell: (row) => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        ),
      },
      {
        Header: 'Prepare by',
        accessor: 'prepared_by_user',
        width: 100,
        minwidth: 100,
        maxwidth: 100,
        style: {
          textAlign: 'center',
          whiteSpace: 'unset',
        },
        Cell: (row) => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        ),
      },
      {
        Header: 'Reviewer Date',
        accessor: 'reviewed_date',
        width: 100,
        minwidth: 100,
        maxwidth: 100,
        style: {
          textAlign: 'center',
        },
        Cell: (row) => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        ),
      },
      {
        Header: 'Review by',
        accessor: 'reviewed_by_user',
        width: 100,
        minwidth: 100,
        maxwidth: 100,
        style: {
          textAlign: 'center',
          whiteSpace: 'unset',
        },
        Cell: (row) => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        ),
      },
      {
        Header: '',
        filterable: false,
        fixed: 'right',
        columns: [
          {
            Header: '',
            accessor: 'savestatus',
            filterable: false,
            width: 60,
            minwidth: 40,
            maxwidth: 40,
            style: {
              paddingLeft: 10,
            },
            Cell: (props) => {
              let clidIndex = this.state.checklists
                .map((item) => {
                  return item.checklist_line_id
                })
                .indexOf(props.original.checklist_line_id)
              let clone = this.state.checklists
              return clone[clidIndex].savestatus === 'Success to Save' ? (
                <div>
                  <img
                    src={SuccessLogo}
                    className="attachment-logos"
                    title="Changes saved."
                  />
                </div>
              ) : clone[clidIndex].savestatus === 'Failed to Save' ? (
                <div>
                  <img
                    src={FailedLogo}
                    className="attachment-logos"
                    title="Data has changed before you saved. Click [Refresh] to get the latest data before updating."
                  />
                </div>
              ) : (
                <div></div>
              )
            },
          },
        ],
      },
    ]

    const maxPaginationRows = 5000

    let Total = JSON.parse(JSON.stringify(this.state.checklists))

    return (
      <div className="content" onClick={isMenuExpanded ? menuToggle : null}>
        {this.state.render ? this.refreshattachment() : null}
        <div className="fscpdetail-container" style={{height: '100%'}}>
          <div className="fscpdetail-wrapper" style={{height: '100%'}}>
            <h1 id="title">DETAIL</h1>
            <div className="fscpdetail-params-container">
              <div className="fscpdetail-params">
                <div>
                  <table id="fscpDetail-params-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Entity</strong>
                          <br />
                          <strong>Period</strong>
                          <br />
                        </td>
                        <td style={{paddingLeft: '10px'}}>
                          <strong> : </strong>
                          <br />
                          <strong> : </strong>
                          <br />
                        </td>
                        <td style={{paddingLeft: '10px'}}>
                          {entityId}
                          <br />
                          {periodName}
                          <br />
                        </td>
                        <td style={{paddingLeft: '100px'}}></td>
                        <td>
                          <strong>CheckList Number</strong>
                          <br />
                          <strong>Reviewed</strong>
                          <br />
                        </td>
                        <td style={{paddingLeft: '10px'}}>
                          <strong> : </strong>
                          <br />
                          <strong> : </strong>
                          <br />
                        </td>
                        <td style={{paddingLeft: '10px'}}>
                          {checklistnumber}
                          <br />
                          {this.ChecklistReviewed()}
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="fscpdetail-btn-wrapper">
                {this.state.accesstype === 'REVIEWER' ? (
                  <button
                    className={
                      this.DisabledButton()
                        ? 'fscpdetail-btn-disabled btn-focus'
                        : 'fscpdetail-btn btn-focus'
                    }
                    onClick={this.reviewAndApprove}
                    disabled={this.DisabledButton()}
                  >
                    Review All
                  </button>
                ) : null}
                {this.state.checklists.length > 0 ? (
                  <ExportFscpDetail
                    entityId={entityId}
                    periodName={periodName}
                    checklistId={checklistId}
                    checklists={this.state.checklists}
                    checklistnumber={checklistnumber}
                  />
                ) : null}
                <button
                  className={
                    this.submitDisabled()
                      ? 'fscpdetail-btn-disabled btn-focus'
                      : 'fscpdetail-btn btn-focus'
                  }
                  disabled={this.submitDisabled()}
                  onClick={(e) => this.setState({SubmittedMessage: true})}
                >
                  Approve
                </button>
                {((this.state.count === Total.length && this.state.AllReviewed) ||
                  this.state.SubmittedMessage) && (
                  <Modal
                    isOpen={this.state.AllReviewed || this.state.SubmittedMessage}
                    onRequestClose={this.closeSubmitAlert}
                    style={submitmessage}
                  >
                    <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
                      Conclusion Statement
                    </h2>
                    I have independently reviewed the closing activities performed
                    according to the FP checklist and FSCP policy. I reviewed workings and
                    conclusion in each lead sheet, where considered necessary or required
                    by the policy, explanations and follow-up actions are discussed,
                    documented and/or carried out to my satisfaction.
                    <br />
                    <br />
                    Apart from above, I have also checked and ensured the data and reports
                    used in the lead sheets are complete and accurate:
                    <br />
                    <p style={{marginLeft: '2%'}}>
                      1.For reports that are directly generated from the accounting
                      systems, the parameters used, including but not limited to
                      accounting period, GL accounts, entity code, product code etc., are
                      correct for extracting required data.
                      <br />
                      2.For reports that involves end-user manual computation:
                    </p>
                    <p style={{marginLeft: '3%'}}>
                      a. raw data interfaced into the worksheet are from the correct
                      source and complete;
                      <br />
                      b. formulas used for computation are correct and as designed; and
                      <br />
                      c. end result is complete and accurate for further analysis or
                      journal posting.
                      <br />
                    </p>
                    <div className="fscp-createalert-wrapper">
                      <button
                        className="fscp-createalert-btn btn-focus"
                        disabled={this.submitDisabled()}
                        onClick={(e) => this.submitData()}
                      >
                        Approve
                      </button>
                    </div>
                  </Modal>
                )}
                {this.state.isSubmitted && (
                  <Modal
                    isOpen={this.state.isSubmitted}
                    onRequestClose={this.closeCreateAlert}
                    style={alertCreateStyles}
                  >
                    <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
                      Checklist {checklistnumber} has been Approve successfully.
                      <br />
                      Click [Ok] to close this window.
                      <br />
                    </h2>
                    <br />
                    <div className="fscp-createalert-wrapper">
                      <button
                        className="fscp-createalert-btn btn-focus"
                        onClick={this.closeCreateAlert}
                      >
                        Ok
                      </button>
                    </div>
                  </Modal>
                )}
                <button
                  className={
                    this.DisabledButton()
                      ? 'fscpdetail-btn-disabled btn-focus'
                      : 'fscpdetail-btn btn-focus'
                  }
                  onClick={(e) => {
                    this.setState({isRefresh: true})
                  }}
                  disabled={this.DisabledButton()}
                >
                  Refresh
                </button>
                {this.state.isRefresh && (
                  <Modal
                    isOpen={this.state.isRefresh}
                    onRequestClose={this.closeExportAlert}
                    style={alertCreateStyles}
                  >
                    <h2>
                      Unsaved changes will be discarded if you refresh before saving.
                      <br />
                      Click [Ok] to proceed or [Cancel] and save your changes before
                      refreshing.
                      <br />
                    </h2>
                    <br />
                    <div className="fscp-createalert-wrapper">
                      <button
                        className="fscp-createalert-btn btn-focus"
                        onClick={(e) => {
                          this.setState({
                            checklists: [],
                            isRefresh: false,
                            refreshupdate: true,
                          })
                          this.fetchDetailDataHandler(this.state.checklistId)
                        }}
                      >
                        Ok
                      </button>
                      <button
                        className="fscp-createalert-btn btn-neutral"
                        onClick={(e) => this.closeExportAlert()}
                      >
                        Cancel
                      </button>
                    </div>
                  </Modal>
                )}
                <button
                  className={
                    this.DisabledButton()
                      ? 'fscpdetail-btn-disabled btn-focus'
                      : 'fscpdetail-btn btn-focus'
                  }
                  onClick={(e) => {
                    this.setState({isSave: true})
                    this.SaveLines(true)
                    this.setState({reviewedRender: true})
                  }}
                  disabled={this.DisabledButton()}
                >
                  Save
                </button>

                {this.state.isSave && (
                  <Modal
                    isOpen={this.state.isSave}
                    onRequestClose={this.closeExportAlert}
                    style={SaveStyles}
                    shouldCloseOnOverlayClick={false}
                  >
                    <div className="fscp-createalert-wrapper">
                      <ReactLoading
                        type={'spin'}
                        color={'blue'}
                        height={'20%'}
                        width={'20%'}
                      />
                      {this.state.count === Total.length
                        ? this.setState({isSave: false})
                        : null}
                    </div>
                  </Modal>
                )}

                {this.state.isReviewingAll && (
                  <Modal
                    isOpen={this.state.isReviewingAll}
                    onRequestClose={this.closeExportAlert}
                    style={ReviewingAllStyles}
                    shouldCloseOnOverlayClick={false}
                  >
                    <div className="fscp-createalert-wrapper">
                      <div>
                        <h2>
                          Checklists are being reviewed. Please do not refresh or close
                          this tab until the reviewing is complete.
                        </h2>
                        <div
                          className="reviewingAll-spinner"
                          style={{paddingLeft: '45%', paddingTop: '10%'}}
                        >
                          <ReactLoading
                            type={'spin'}
                            color={'blue'}
                            height={'20%'}
                            width={'20%'}
                          />
                          {this.state.count === Total.length
                            ? this.setState({isReviewingAll: false})
                            : null}
                        </div>
                      </div>
                    </div>
                  </Modal>
                )}
              </div>
            </div>
          </div>
          <div className="fscp-detail">
            <ReactTable
              data={this.state.templateTypes}
              columns={templateColumns}
              defaultPageSize={4}
              minRows={0}
              noDataText={null}
              filterable={false}
              showPageSizeOptions={false}
              showPagination={false}
              className="-highlight"
              expanded={this.state.expandedTemplates}
              onExpandedChange={(newExpanded, index, e, expandedType) => {
                this.setExpandedRows(newExpanded, index, e)
              }}
              SubComponent={(row) => {
                const {checklists} = this.state
                const checklistGroups = checklists.filter((item) =>
                  item.template_type.includes(row.original.template_type)
                )
                return (
                  <div style={{padding: '0px'}}>
                    <ReactTableFixedColumns
                      data={checklistGroups}
                      columns={checklistColumns}
                      filterable={true}
                      //defaultPageSize={checklistGroups.length > maxPaginationRows ? maxPaginationRows : checklistGroups.length}
                      pageSize={checklistGroups.length}
                      minRows={0}
                      style={{height: '500px'}}
                      showPageSizeOptions={false}
                      showPagination={
                        checklistGroups.length > maxPaginationRows ? true : false
                      }
                      defaultFilterMethod={this.filterCaseInsensitive}
                      className="-striped -highlight"
                    />
                  </div>
                )
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withCookies(withRouter(FscpDetail))
