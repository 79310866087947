/* global gapi */
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginError: false,
            redirect: false,
            SignIn: false,
            cookies: this.props.cookies.get('Logins')
        };
    }


    componentDidMount() {
        if (this.state.cookies) {
            window.gapi.load('auth2', () => {
                window.gapi.auth2.init({
                    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
                    cookie_policy: 'none'
                }).then(() => {
                    if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
                        gapi.auth2.getAuthInstance().signIn({
                            ux_mode: 'redirect',
                            redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
                            state: window.URLRequested
                        })
                    } else {
                        gapi.auth2.getAuthInstance().disconnect()
                        gapi.auth2.getAuthInstance().signIn({
                            ux_mode: 'redirect',
                            redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
                            state: window.URLRequested
                        })
                    }
                })
            });
        } else {
            console.log('test')
            window.gapi.load('auth2', () => {
                window.gapi.auth2.init({
                    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
                }).then(() => {
                    if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
                        gapi.auth2.getAuthInstance().signIn({
                            ux_mode: 'redirect',
                            redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
                            state: window.URLRequested
                        })
                    } else {
                        console.log('test2')
                        gapi.auth2.getAuthInstance().disconnect()
                        gapi.auth2.getAuthInstance().signIn({
                            ux_mode: 'redirect',
                            redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
                            state: window.URLRequested
                        })
                    }
                }).then(() => { this.props.cookies.set('Logins', "Test") })

            });
        }
    }

    render = () => (<div id="loginButton" />)
}

export default withCookies(Login);