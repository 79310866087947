/* global gapi */
import React, {Component} from 'react'
import {BrowserRouter as Router, Switch, Route, withRouter} from 'react-router-dom'
import {withCookies} from 'react-cookie'
import {gapi} from 'gapi-script'
import Toolbar from './components/toolbar/Toolbar'
import Menubar from './components/menubar/Menubar'
import Home from './components/Home'
import Fscp from './components/Fscp'
import FscpDetail from './components/FscpDetail'
import Others from './components/Others'
import GlobalTemplate from './components/globalTemplate'
import './App.css'
import AlertDialog from './components/auth/AlertDialog'

const baseURL = `${process.env.REACT_APP_API_URL}`

let optionsUser = []
//let optionsUserID =[];
//let optionsUserID2;
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cookies: this.props.cookies.get('Logins'),
      token: false,
      UserName: '',
      UserID: '',
      UserEmail: '',
      accesstoken: '',
      useraccess: [],
      render: false,
      authError: false,
    }
  }

  GetUserDetails = () => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2
        .init({
          //client_id: '484166416946-dkll31nh5n87c6so4fdh6nj7nv655ve3.apps.googleusercontent.com',
          client_id: `${process.env.REACT_APP_CLIENT_ID}`,
          cookie_policy: 'none',
        })
        .then(() => {
          //const{UserID,UserEmail,accesstoken}=this.state
          const url = `${baseURL}/fscp/my_entity_access/`
          fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              //'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer google-oauth2 ${
                gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true)
                  .access_token
              }`,
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw Error(response.statusText)
              }

              return response.json()
            })
            .catch((error) => {
              this.setState({authError: true})
              gapi.auth2.getAuthInstance().disconnect()
            })
        })
    })
  }

  componentDidMount() {
    window.gapi.load('auth2', () => {
      window.gapi.auth2
        .init({
          //client_id: '484166416946-dkll31nh5n87c6so4fdh6nj7nv655ve3.apps.googleusercontent.com',
          client_id: `${process.env.REACT_APP_CLIENT_ID}`,
          cookie_policy: 'none',
        })
        .then(() => {
          this.setState({token: gapi.auth2.getAuthInstance().isSignedIn.get()})
          console.log(this.state.token)
          if (this.state.token) {
            this.setState({
              accesstoken: gapi.auth2
                .getAuthInstance()
                .currentUser.get()
                .getAuthResponse(true).access_token,
              UserEmail: gapi.auth2
                .getAuthInstance()
                .currentUser.get()
                .getBasicProfile()
                .getEmail(),
            })

            //this.getuserid()
            if (this.props.cookies.get('Logins') === 'Test') {
              this.props.cookies.set(
                'Logins',
                gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getId(),
                {maxAge: 432000, sameSite: false}
              )
            }
            console.log(gapi.auth2.getAuthInstance().isSignedIn.get())
            this.setState({cookies: this.props.cookies.get('Logins')})
            console.log(this.state.cookies)
            if (this.state.cookies !== 'Test' && this.state.cookies) {
              console.log(this.state.cookies)
              this.GetUserDetails()
            } else {
              window.URLRequested = this.props.history.location.pathname
              this.props.history.push('/Login')
            }
          } else {
            window.URLRequested = this.props.history.location.pathname
            this.props.history.push('/Login')
          }
        })
    })
  }

  setRender = () => {
    this.setState({render: true})
  }

  state = {
    isMenuExpanded: false,
  }

  toggleMenuHandler = () => {
    this.setState((prevState) => {
      return {isMenuExpanded: !prevState.isMenuExpanded}
    })
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Toolbar
            isMenuExpanded={this.state.isMenuExpanded}
            menuToggle={this.toggleMenuHandler}
          />
          <Menubar
            initMenu={this.state.isMenuExpanded}
            isMenuExpanded={this.state.isMenuExpanded}
            menuToggle={this.toggleMenuHandler}
          />
          {this.state.authError ? (
            <AlertDialog />
          ) : (
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Home
                    {...props}
                    isMenuExpanded={this.state.isMenuExpanded}
                    menuToggle={this.toggleMenuHandler}
                  />
                )}
              />
              <Route
                exact
                path="/fscp"
                render={(props) => (
                  <Fscp
                    {...props}
                    isMenuExpanded={this.state.isMenuExpanded}
                    menuToggle={this.toggleMenuHandler}
                  />
                )}
              />
              <Route
                exact
                path="/fscp/checklist/:checklistId"
                render={(props) => (
                  <FscpDetail
                    {...props}
                    isMenuExpanded={this.state.isMenuExpanded}
                    menuToggle={this.toggleMenuHandler}
                  />
                )}
              />
              <Route path="/others" component={Others} />
              <Route
                exact
                path="/globalTemplate"
                render={(props) => (
                  <GlobalTemplate
                    {...props}
                    isMenuExpanded={this.state.isMenuExpanded}
                    menuToggle={this.toggleMenuHandler}
                  />
                )}
              />
            </Switch>
          )}
        </div>
      </Router>
    )
  }
}

export default withCookies(withRouter(App))
