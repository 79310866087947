import React from 'react';

const Others = props => {
  return (
    <div className="Others">
      <h2>This is Others page</h2>
    </div>
  );
};

export default Others;